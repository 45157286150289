import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./../Page/Login";
import Auth from "./../Page/Auth/Auth";
import { useSelector } from "react-redux";
import Verification from "./../Page/Verfication/index";
import Pipeline from "../Page/Pipeline2/Pipeline";
import Lead from "../Page/Lead";
import Followup from "../Page/Followup/index";
import ManageDelivery from "../Page/ManageDelivery";
import Support from "../Page/Support";
import Reports from "../Page/Reports";
import Conversion from "../Page/Conversion";
import UploadMenu from "../Page/UploadMenu/UploadMenu";
import Chat from "../Page/Chat/Chat";
import Theme from "../Page/Theme";
const defaultRoute = (
  <Switch>
    <Route exact path="/" component={Login} />
  </Switch>
);

const authRoute = (
  <Switch>
    <Route exact path="/" component={Followup}></Route>
    <Route exact path="/verification" component={Verification}></Route>
    <Route exact path="/pipeline" component={Pipeline}></Route>
    <Route exact path="/lead" component={Lead}></Route>
    <Route exact path="/manageDelivery" component={ManageDelivery}></Route>
    <Route exact path="/ticket" component={Support}></Route>
    <Route exact path="/reports" component={Reports}></Route>
    <Route exact path="/conversion" component={Conversion}></Route>
    <Route exact path="/upload-menu" component={UploadMenu}></Route>
    <Route exact path="/chat" component={Chat}></Route>
    <Route exact path="/theme" component={Theme}></Route>
  </Switch>
);

export default function AppRouter() {
  const islogin = useSelector((state) => state.islogin);
  console.log("islogin", islogin);
  return (
    <BrowserRouter>
      {islogin ? <Auth route={authRoute} /> : defaultRoute}
    </BrowserRouter>
  );
}
