import React from "react";
import InfoCard from "../../component/InfoCard";
const underReview =
  window.location.origin + "/assets/Verification/underReview.svg";
const pending = window.location.origin + "/assets/Verification/pending.svg";
const verified = window.location.origin + "/assets/Verification/verified.svg";

const storeImg = window.location.origin + "/assets/ReportStore.svg";
const merChantImg = window.location.origin + "/assets/ReportMerchant.svg";
const cityImg = window.location.origin + "/assets/ReportCity.svg";

export default function CardContiner({ record }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard
        style={{ width: "30%" }}
        count={record?.total_merchants}
        title={"Merchants Registered"}
        img={merChantImg}
      />
      <InfoCard
        style={{ width: "30%" }}
        count={record?.total_restaurants + record?.total_retails}
        title={"Stores Registered"}
        img={storeImg}
      />
      <InfoCard
        style={{ width: "30%" }}
        count={record?.total_cities}
        title={"Total Cities and Towns Covered"}
        img={cityImg}
      />
    </div>
  );
}
