import React from "react";
import Info from "./Info";

export default function Plan() {
  return (
    <div className="rd-plan">
      <Info />
    </div>
  );
}
