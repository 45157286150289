import React, { useState, useEffect } from "react";
import { Button, Dialog, IconButton, TextField } from "@material-ui/core";
import Api from "../../../Core/Api";
import moment from "moment";

export default function AddFollowupDialog({
  open,
  onClose,
  lead_id,
  refetch,
  setToast,
}) {
  const [text, setText] = useState("");
  const [date, setDate] = useState();
  const [localDate, setLocalDate] = useState(null);

  const handleDateChange = (date) => {
    setDate(Date.parse(`${date} 23:59:00 `));
    setLocalDate(date);
  };

  useEffect(() => {
    if (open) {
      setText("");
      setLocalDate(null);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const temp = {
      text: text,
      lead_id: lead_id,
      follow_up_at: date,
    };
    Api.addNewFollowup(temp)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          onClose();
          setToast({
            open: true,
            message: "New Followup added!",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => onClose()}>
        <div className="add-followup-dialog">
          <h1>Add Followup</h1>

          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              required
              onChange={(e) => setText(e.target.value)}
              id="standard-required"
              label="Enter Text"
              value={text}
              multiline
              rows={3}
            />

            <TextField
              style={{ marginTop: "20px" }}
              id="date"
              label="Select Date"
              type="date"
              value={localDate}
              required
              // type="datetime-local"
              onChange={(e) => handleDateChange(e.target.value)}
              // defaultValue="2017-05-24"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              type="submit"
              style={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
}
