import React, { useEffect, useState } from "react";
import BusinessInfo from "./BusinessInfo";
import Info from "./Info";
import LiveMenuBar from "./LiveMenuBar";
import { Button } from "@material-ui/core";
import { useQuery, gql } from "@apollo/client";
import Api from "../../../Core/Api";
import Toast from "../../Toast/Toast";
import Tags from "./Tags";
import PiplineCTA from "../../StyledComponent/PiplineCTA";
import { Link } from "react-router-dom";
import AssignLeadDialog from "./AssignLead";
const FetchBasicInfo = gql`
  query FetchRestaurantBasicDetails($lead_id: BigInt) {
    FetchRestaurantBasicDetails(lead_id: $lead_id) {
      lead_id
      pipeline_id
      restaurant_name
      brand_name
      buisness_name
      contact_person_name
      contact_person_mobile
      is_menu_verified
      is_menu_uploaded
      is_documentation_complete
      is_document_verified
      is_restaurant_detail_complete
      live_menu_link
      owner_mobile
      owner_name
      created_at
      created_by
      store_type
      assignee {
        fname
        lname
      }
      images {
        url
      }
      tags {
        text
        color
      }
    }
  }
`;

export default function Details({
  id,
  pipelineList,
  pipelineId,
  index,
  refresh,
  onClose,
}) {
  const [data, setData] = useState("");
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });
  const {
    data: tempdata,
    refetch,
    loading,
  } = useQuery(FetchBasicInfo, {
    variables: {
      lead_id: id,
    },
  });
  console.log("Tdata", pipelineId, pipelineList);

  useEffect(() => {
    if (true) {
      if (tempdata) {
        console.log("RD Basic info", tempdata);
        setData(tempdata?.FetchRestaurantBasicDetails);
      }
    }
  }, [tempdata]);

  const changePipeline = (pipelineid) => {
    var temp = {
      lead_id: id,
      pipeline_id: pipelineid,
    };

    console.log("Local data", temp);
    Api.movePipeLine(temp)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refresh();
          setToast({
            open: true,
            message: "Pipeline Changed!",
            isSuccess: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setToast({
          open: true,
          message: "Something Went Wrong!",
          isSuccess: false,
        });
      });
  };

  return (
    <div className="rd-details">
      <div className="topbar">
        <h1>
          Business Details
          {/* {id} p{data?.pipeline_id} */}
        </h1>
        {/* <Button
          onClick={() => setOpen(true)}
          color="primary"
          variant="contained"
          size="small"
        >
          Assign
        </Button> */}
        {data?.pipeline_id && (
          <PiplineCTA
            component={Link}
            to={`/pipeline?id=${data?.pipeline_id}&mobile=${data.owner_mobile}`}
            onClick={() => onClose()}
          />
        )}
      </div>
      <Tags data={data?.tags} lead_id={id} refetch={() => refetch()} />

      <Info data={data} />
      <LiveMenuBar
        index={index}
        pipelineid={pipelineId}
        changePipeline={(e) => changePipeline(e)}
        pipeLineList={pipelineList}
        link={data?.live_menu_link}
      />
      <BusinessInfo data={data} />
      <Toast
        data={toast}
        handleClose={() => {
          setToast({
            open: false,
            message: "",
            isSuccess: false,
          });
        }}
        open={toast.open}
      />
      <AssignLeadDialog open={open} onClose={() => setOpen(false)} />
    </div>
  );
}
