import React from "react";

export default function Title({ title }) {
  return (
    <div>
      <h1
        style={{
          margin: "0",
          padding: "0",
          fontSize: "32px",
          fontWeight: "bold",
          lineHeight: "41px",
          color: "#333333",
        }}
      >
        {title}
      </h1>
    </div>
  );
}
