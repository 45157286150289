import React, { useState, useEffect } from "react";
import CartContainer from "./CartContainer";
import ThemeHeader from "./ThemeHeader";
import { Drawer } from "@material-ui/core";
import ThemeDrawer from "./ThemeDrawer";
import Api from "../../Core/Api";
import ThemeLoader from "./ThemeLoader";

export default function Theme() {
  const [isNext, setIsNext] = useState(false);
  const [search, setSearch] = useState("");
  const [catogery, setCatogery] = useState("ALL");
  const [layout, setLayout] = useState("ALL");
  const [drawer, setDrawer] = useState(false);
  const [themes, setThemes] = useState([]);
  const [draweType, setDrawerType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState("");

  console.log("themes", themes);

  useEffect(() => {
    setLoading(true);
    Api.getTheme(page, search, catogery, layout)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          console.log("REs", res);
          setThemes(res?.data.data?.rows);
          setIsNext(res?.data?.data?.has_next);
          setLoading(false);
        }
      })
      .catch((error) => console.log("Error", error));
  }, [refresh]);

  return (
    <div>
      <main>
        <ThemeHeader
          search={search}
          setSearch={(e) => {
            setPage(1);
            setSearch(e);
            setRefresh(!refresh);
          }}
          catogery={catogery}
          setCatogery={(e) => {
            setPage(1);
            setCatogery(e);
            setRefresh(!refresh);
          }}
          layout={layout}
          setLayout={(e) => {
            setPage(1);
            setLayout(e);
            setRefresh(!refresh);
          }}
          openDrawer={() => {
            setDrawerType("ADD");
            setDrawer(true);
          }}
        />
        {loading ? (
          <ThemeLoader />
        ) : (
          <CartContainer
            themes={themes}
            isNext={isNext}
            page={page}
            onEdit={(e) => {
              setDrawerType("EDIT");
              setEditData(e);
              setDrawer(true);
            }}
            setPage={(e) => {
              setPage(e);
              setRefresh(!refresh);
            }}
          />
        )}
        <Drawer open={drawer} anchor={"right"} onClose={() => setDrawer(false)}>
          <ThemeDrawer
            type={draweType}
            open={drawer}
            editData={editData}
            refetch={() => setRefresh(!refresh)}
            onClose={() => {
              setDrawerType("");
              setDrawer(false);
            }}
          />
        </Drawer>
      </main>
    </div>
  );
}
