import React from "react";
import ThemeCard from "./ThemeCard";
import { Button } from "@material-ui/core";
// import InfiniteScroll from "react-infinite-scroll-component";

export default function CartContainer({
  themes,
  isNext,
  onEdit,
  page,
  setPage,
}) {
  return (
    <div className="theme-card-container">
      {themes?.map((data, index) => (
        <ThemeCard data={data} onEdit={onEdit} key={index} />
      ))}
      <div className="cta-container">
        <Button
          disabled={page === 1 ? true : false}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setPage(page - 1)}
        >
          Prev
        </Button>
        <Button
          onClick={() => setPage(page + 1)}
          disabled={!isNext}
          variant="contained"
          color="primary"
          size="small"
        >
          Next
        </Button>
      </div>
    </div>
  );
}
