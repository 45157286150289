import React from "react";
import InfoCard from "../../component/InfoCard";
import moment from "moment";
const merChantImg = window.location.origin + "/assets/ReportMerchant.svg";
const pending = window.location.origin + "/assets/Verification/pending.svg";
const verified = window.location.origin + "/assets/Verification/verified.svg";
const Leadsicon = window.location.origin + "/assets/Leads/leads.svg";
export default function CardContiner({ data }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard
        count={data?.todays_untouch_lead}
        title={`${moment(new Date()).format("DD MMMM")} untouched leads`}
        img={verified}
      />
      <InfoCard
        count={data?.assinged_leads}
        title={"Assigned leads"}
        img={Leadsicon}
      />
      <InfoCard
        count={data?.activated_leads}
        title={"Activated leads"}
        img={verified}
      />
      <InfoCard
        count={data?.pending_leads}
        title={"Pending leads"}
        img={pending}
      />
    </div>
  );
}
