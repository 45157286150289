import React, { CircularProgress } from "react";
import { Button } from "@material-ui/core";

export default function BtnBar({ verify, reject, process2 }) {
  console.log("Process 2", process2);
  return (
    <div className="btn-bar">
      <div>
        <Button
          onClick={() => reject()}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Reject
        </Button>

        <Button
          onClick={() => verify()}
          variant="contained"
          color="primary"
          fullWidth
        >
          {false ? (
            <CircularProgress color="inherit" size={20} thickness="5" />
          ) : (
            "Verify"
          )}
        </Button>
      </div>
    </div>
  );
}
