import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import UserName from "./../../component/UserName";
import CustomPagination from "../../component/CustomPagination";
import Card from "./Card";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
    marginBottom: "20px",
  },
});

export default function UploadMenuTable({
  data,
  setToast,
  refresh,
  toatalPage,
  page,
  setPage,
  totalEntries,
}) {
  // console.log("Verificaton data", data);
  const classes = useStyles2();

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        className={classes.table}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow style={{ backgroundColor: "rgba(98, 0, 238, 0.12)" }}>
            <TableCell style={{ fontWeight: "bold" }}>{"Store Name"}</TableCell>

            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Business"}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Register Date"}
            </TableCell>

            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Document Status"}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Activation Status"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Upload"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Action"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowindex) => (
            <Card
              row={row}
              key={rowindex}
              setToast={setToast}
              refresh={refresh}
            />
          ))}
        </TableBody>
      </Table>
      <CustomPagination
        totalEntries={totalEntries}
        toatalPage={toatalPage}
        page={page}
        setPage={setPage}
      />
    </TableContainer>
  );
}
