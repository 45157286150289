import React, { useState } from "react";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

export default function ChatBar({ text, setText, status, sendText }) {
  return (
    <div className="chat-bar">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendText(text);
        }}
      >
        <OutlinedInput
          value={text}
          variant="outlined"
          error={false}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          disabled={status === "OPEN" ? false : true}
          placeholder="Enter Msg"
          endAdornment={
            <InputAdornment position="end">
              {text !== "" && (
                <IconButton
                  disabled={text === "" ? true : false}
                  onClick={() => sendText(text)}
                  size="medium"
                  style={{
                    // backgroundColor: "#6200EE",
                    color: "blue",
                  }}
                  aria-label="toggle password visibility"
                  // edge="end"
                >
                  <SendIcon style={{ fontSize: "25px" }} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </form>
    </div>
  );
}
