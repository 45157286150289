import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function Loader({ loading, empty }) {
  return (
    <div
      style={{
        height: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? <CircularProgress /> : empty && <h1>Empty</h1>}
    </div>
  );
}
