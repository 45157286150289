import React from "react";
import InfoCard from "../../component/InfoCard";
const underReview =
  window.location.origin + "/assets/Verification/underReview.svg";
const pending = window.location.origin + "/assets/Verification/pending.svg";
const verified = window.location.origin + "/assets/Verification/verified.svg";
const rejected = window.location.origin + "/assets/Verification/rejected.svg";
const one = window.location.origin + "/assets/issues/1.svg";

export default function CardContiner({ data }) {
  console.log("Ss", data);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard
        style={{ width: "30%" }}
        count={data?.total}
        title={"Total"}
        img={one}
      />
      <InfoCard
        style={{ width: "30%" }}
        count={data?.menu_uploaded}
        title={"Uploaded"}
        img={verified}
      />
      <InfoCard
        style={{ width: "30%" }}
        count={data?.menu_pending}
        title={"Upload Pending"}
        img={pending}
      />
    </div>
  );
}
