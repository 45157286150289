import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import Api from "../../../Core/Api";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";

const FetchAllPipelinesList = gql`
  query {
    FetchAllPipelinesList {
      name
      states
      id
    }
    FetchAllUser {
      id
      fname
    }
  }
`;

export default function AssignLeadDialog({
  open,
  onClose,
  lead_id,
  refetch,
  setToast,
}) {
  const { loading, error, data } = useQuery(FetchAllPipelinesList);

  const [memberList, setMemberList] = useState([]);
  const [memberId, setMemberId] = useState(0);
  useEffect(() => {
    if (data) {
      console.log("pipeline ", data);
      setMemberList(data.FetchAllUser);
      setMemberId(0);
      // setPipeLineIndex(parseInt(0));
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const temp = {
      //   text: text,
      //   lead_id: lead_id,
      //   follow_up_at: date,
    };
    Api.addNewFollowup(temp)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          onClose();
          setToast({
            open: true,
            message: "New Followup added!",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => onClose()}>
        <div className="add-followup-dialog">
          <h1>Assign Lead</h1>

          <Button
            type="submit"
            style={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
