import React from "react";

export default function PipeLineTitle({ pipelineData, data }) {
  return (
    <>
      <div
        style={{
          fontWeight: "bold",
          fontSize: "18px",
          textAlign: "center",
          padding: "10px 0px",
        }}
      >
        <span>{data}</span>&nbsp;
        {pipelineData.filter((temp, index) => temp.state === data).length >
          0 && (
          <span>
            {`: ${
              pipelineData.filter((temp, index) => temp.state === data).length
            }`}
          </span>
        )}
      </div>
    </>
  );
}
