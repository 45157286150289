import { Button } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Pagination from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";

export default function CustomPagination({
  toatalPage,
  page,
  setPage,
  totalEntries,
}) {
  const total = totalEntries;
  let second = page * 20;
  const first = second - 20 + 1;
  if (second > totalEntries) second = totalEntries;

  const { sidebarOpen } = useSelector((state) => state);

  return (
    <div
      style={{ width: sidebarOpen ? "78vw" : "95vw" }}
      className="custom-pegination"
    >
      <div className="txt">
        <h1>
          Showing{" "}
          <span>
            {first}-{second}
          </span>{" "}
          of <span>{total}</span> entries
        </h1>
      </div>
      <div>
        <Pagination
          count={toatalPage}
          page={page}
          onChange={(e, v) => setPage(v)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
}
