/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import { Button, Menu } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ColorPicker, useColor } from "react-color-palette";
import Api from "../../Core/Api";
import "react-color-palette/lib/css/styles.css";
import DrawerFilter from "./DrawerFilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import { prominent } from "color.js";
const PencilIcon = window.location.origin + "/assets/Theme/pencil.svg";

export default function ThemeDrawer({
  type,
  open: drawerOpen,
  refetch,
  editData,
  onClose,
}) {
  const [color, setColor] = useColor("hex", "#642e2e");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ref = createRef(null);

  const [selectedColorCard, setSelectedColorCard] = useState(null);

  const [uploadedImg, setUploadedImg] = useState("");
  const [imageUplaodLoading, setImageUplaodLoading] = useState(false);

  const [screenshot, setScreenShot] = useState("");
  const [screenshotLoading, setScreenShotLoading] = useState(false);

  const [primayColor, setPrimaryColor] = useState("#a03232");
  const [secondaryColor, setSecondaryColor] = useState("#2eb95a");
  const [disableCtaColor, setDisableCtaColor] = useState("#cbcec7");
  const [disableCtaTextColor, setDisableCtaTextColor] = useState("#ee0000");
  const [ctaTextColor, setTextCtaColor] = useState("#ee0000");

  const [colors, setColors] = useState([]);

  const [catogery, setCatogery] = useState("");
  const [layout, setLayout] = useState("");

  const [themeName, setThemeName] = useState("");
  const [displayPrice, setDisplayPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");

  useEffect(() => {
    if (type === "EDIT") {
      console.log("EDIT DATA", editData);
      setPrimaryColor(editData?.extra_data?.primary_color);
      setSecondaryColor(editData?.extra_data?.secondary_color);
      setDisableCtaColor(editData?.extra_data?.disable_cta_color);
      setDisableCtaTextColor(editData?.extra_data?.disable_cta_text_color);
      setTextCtaColor(editData?.extra_data?.cta_text_color);

      setCatogery(editData?.theme_category_id);
      setLayout(editData?.theme_layout_code);
      setThemeName(editData?.name);
      setUploadedImg(editData?.cover_image);
      setScreenShot(editData?.image);
      setSellingPrice(Number(editData?.price / 100));
      setDisplayPrice(Number(editData?.display_price / 100));
    }
  }, [type]);

  useEffect(() => {
    if (selectedColorCard === null) {
      return;
    } else {
      setColorinCard();
    }
  }, [color]);

  useEffect(() => {
    drawerOpen && reloadFrame();
  }, [drawerOpen]);

  useEffect(() => {
    reloadFrame();
  }, [
    layout,
    primayColor,
    secondaryColor,
    disableCtaColor,
    disableCtaTextColor,
    ctaTextColor,
    uploadedImg,
  ]);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setImageUplaodLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      uplaodImage(formData);
    }
  };
  const handleScreenShotUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setScreenShotLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      uplaodScreenShot(formData);
    }
  };

  const getColors = (e) => {
    console.log("Colors", e);
    e.length > 0 && setPrimaryColor(e[0]);
    e.length > 1 && setSecondaryColor(e[1]);
    e.length > 2 && setDisableCtaColor("#b2ad9d"); // hard coded
    e.length > 3 && setDisableCtaTextColor("#000");
    e.length > 4 && setTextCtaColor(e[4]);

    setColors(e);
  };

  const handleSave = (e) => {
    // getImage();
    // captureScreenshot();

    e.preventDefault();
    const data = {
      name: themeName,
      image: screenshot,
      cover_image: uploadedImg,
      description: "",
      price: Number(sellingPrice * 100),
      display_price: Number(displayPrice * 100),
      category_id: catogery,
      layout_code: layout,
      primary_color: primayColor,
      secondary_color: secondaryColor,
      cta_text_color: ctaTextColor,
      disable_cta_color: disableCtaColor,
      disable_cta_text_color: disableCtaTextColor,
    };

    if (type === "EDIT") {
      data.id = editData?.id;

      console.log("Edit Dta", data);
      Api.updateTheme(data)
        .then((res) => {
          console.log("Update Theme", res);
          if (res?.data?.status_code === 200) {
            refetch();
            onClose();
          }
        })
        .catch((error) => console.log("Error"));
    } else if (type === "ADD") {
      Api.createTheme(data)
        .then((res) => {
          console.log("Create Theme", res);
          if (res?.data?.status_code === 200) {
            refetch();
            onClose();
          }
        })
        .catch((error) => console.log("Error"));
    }
  };

  const uplaodImage = (formData) => {
    formData.append("upload_preset", "ypqeu3mz");
    formData.append("api_key", 324683829857419);
    formData.append("timestamp", Math.round(new Date().getTime() / 1000));
    formData.append("folder", "india/restaurants/restarant_id/temp/");
    const url = "https://api.cloudinary.com/v1_1/myedugatetech/image/upload";
    axios
      .post(url, formData)
      .then((response) => {
        console.log("image Upload", response);
        if (response?.status === 200) {
          setUploadedImg(response?.data?.secure_url);

          // implemented with color.js
          prominent(response?.data?.secure_url, {
            format: "hex",
            amount: 5,
            group: 10,
          }).then((colors) => {
            setImageUplaodLoading(false);
            getColors(colors);
            console.log("COLORS-R", colors);
          });
        }
      })
      .catch((error) => console.log("Error", error));
  };

  const uplaodScreenShot = (formData) => {
    formData.append("upload_preset", "ypqeu3mz");
    formData.append("api_key", 324683829857419);
    formData.append("timestamp", Math.round(new Date().getTime() / 1000));
    formData.append("folder", "india/restaurants/restarant_id/temp/");
    const url = "https://api.cloudinary.com/v1_1/myedugatetech/image/upload";
    axios
      .post(url, formData)
      .then((response) => {
        console.log("image Upload", response);
        if (response?.status === 200) {
          setScreenShot(response?.data?.secure_url);
          setScreenShotLoading(false);
        }
      })
      .catch((error) => console.log("Error", error));
  };

  const setColorinCard = () => {
    switch (selectedColorCard) {
      case "primary":
        setPrimaryColor(color?.hex);

        break;
      case "secondary":
        setSecondaryColor(color?.hex);

        break;
      case "disablePrimary":
        setDisableCtaColor(color?.hex);

        break;
      case "disableSecondary":
        setDisableCtaTextColor(color?.hex);

        break;
      case "cta":
        setTextCtaColor(color?.hex);

        break;
      default:
        setSelectedColorCard(null);
        break;
    }
  };

  const reloadFrame = () => {
    // document.getElementById("myframe").contentWindow.location.reload(true);

    document.getElementById("myframe").contentWindow.location.replace(
      `https://becho-client-pwa.web.app/?demo=TRUE&primary=${primayColor.replace(
        "#",
        "%23"
      )}&secondary=${secondaryColor?.replace(
        "#",
        "%23"
      )}&cta_text=${ctaTextColor?.replace(
        "#",
        "%23"
      )}&disable_cta=${disableCtaColor?.replace("#", "%23")}
        &disable_cta_text=${disableCtaTextColor?.replace(
          "#",
          "%23"
        )}&layout=${layout}&cover_img=${uploadedImg}`
    );
  };

  console.log("Layout", layout);
  return (
    <div className="theme-drawer">
      <div className="phone-box" ref={ref}>
        <div class="smartphone">
          <div class="content">
            <iframe
              id="myframe"
              src={"https://becho-client-pwa.web.app/"}
              style={{ width: "100%", height: "100%", border: "none" }}
            />
          </div>
        </div>
        <div className="color-box">
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
              setSelectedColorCard(null);
              reloadFrame();
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ColorPicker
              width={250}
              height={150}
              color={color}
              onChange={setColor}
              hideHSV
              hideRGB
              dark
            />
          </Menu>

          <div>
            <div className="color-card">
              <div style={{ backgroundColor: primayColor }}></div>
              <p>primary</p>
              <img
                src={PencilIcon}
                alt=""
                onClick={(event) => {
                  setSelectedColorCard("primary");
                  setAnchorEl(event.currentTarget);
                }}
              />
            </div>
            <div className="color-card">
              <div style={{ backgroundColor: secondaryColor }}></div>
              <p>Secondary</p>
              <img
                src={PencilIcon}
                alt=""
                onClick={(event) => {
                  setSelectedColorCard("secondary");
                  setAnchorEl(event.currentTarget);
                }}
              />
            </div>
            <div className="color-card">
              <div style={{ backgroundColor: ctaTextColor }}></div>
              <p>Button Text </p>
              <img
                src={PencilIcon}
                alt=""
                onClick={(event) => {
                  setSelectedColorCard("cta");
                  setAnchorEl(event.currentTarget);
                }}
              />
            </div>
            <div className="color-card">
              <div style={{ backgroundColor: disableCtaColor }}></div>
              <p>Disable Button</p>
              <img
                src={PencilIcon}
                alt=""
                onClick={(event) => {
                  setSelectedColorCard("disablePrimary");
                  setAnchorEl(event.currentTarget);
                }}
              />
            </div>
            <div className="color-card">
              <div style={{ backgroundColor: disableCtaTextColor }}></div>
              <p>Disable Button text</p>
              <img
                src={PencilIcon}
                alt=""
                onClick={(event) => {
                  setSelectedColorCard("disableSecondary");
                  setAnchorEl(event.currentTarget);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div className="close-icon">
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </div>

        <form onSubmit={(e) => handleSave(e)}>
          <DrawerFilter
            catogery={catogery}
            setCatogery={setCatogery}
            layout={layout}
            setLayout={(e) => {
              setLayout(e);
            }}
          />
          <div className="input-container">
            <input
              required
              value={themeName}
              onChange={(e) => setThemeName(e.target.value)}
              placeholder="Enter Theme Name"
            />
            <input
              type="number"
              required
              value={displayPrice}
              onChange={(e) => setDisplayPrice(e.target.value)}
              placeholder="Enter Display Price"
            />
            <input
              type="number"
              required
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
              placeholder="Enter Selling Price"
            />
          </div>

          <div className="image-upload">
            <p>Upload Cover</p>

            <div className="image-upload-box">
              {imageUplaodLoading ? (
                <CircularProgress size={15} />
              ) : (
                <label>
                  <input
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <p>
                    <span>Uplod Image </span>
                  </p>
                </label>
              )}
            </div>
          </div>

          {uploadedImg && (
            <div className="uploaded-img">
              {/* <ColorExtractor getColors={(e) => getColors(e)}>
                <img src={uploadedImg} alt="" />
              </ColorExtractor> */}
              <img src={uploadedImg} alt="" />
            </div>
          )}

          <div className="image-upload">
            <p>Upload Screenshot</p>

            <div className="image-upload-box">
              {screenshotLoading ? (
                <CircularProgress size={15} />
              ) : (
                <label>
                  <input
                    onChange={handleScreenShotUpload}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  />
                  <p>
                    <span>Uplod Image </span>
                  </p>
                </label>
              )}
            </div>
          </div>
          {screenshot && (
            <div className="uploaded-img">
              <img src={screenshot} alt="" />
            </div>
          )}

          <div className="cta-container">
            {type === "ADD" && (
              <Button
                type="submit"
                fullWidth
                size="small"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            )}
            {type === "EDIT" && (
              <Button
                type="submit"
                fullWidth
                size="small"
                variant="contained"
                color="primary"
              >
                Edit
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
