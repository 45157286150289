import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import IssueCard from "./IssueCard";

const FetchAllIssue = gql`
  query FetchAllIssue($lead_id: BigInt) {
    FetchAllIssue(lead_id: $lead_id) {
      type
      status
      id
      created_at
      messages {
        text
      }
      restaurant_lead {
        restaurant_name
      }
    }
  }
`;
export default function Issue({ setIssueId, lead_id }) {
  const {
    loading,
    data: tdata1,
    refetch,
  } = useQuery(FetchAllIssue, {
    variables: {
      lead_id: lead_id,
    },
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    refetch();
    if (tdata1) {
      console.log("Fetch Issues", data);
      setData(tdata1?.FetchAllIssue);
    }
  }, [tdata1]);

  // console.log("data", data);

  return (
    <div className="rd-issues">
      <div className="topbar">
        <h1>Issues</h1>
      </div>
      <div className="card-container">
        {data?.map((data, key) => (
          <IssueCard data={data} key={key} setIssueId={(e) => setIssueId(e)} />
        ))}
      </div>
    </div>
  );
}
