const SET_IS_USER_LOGIN = "SET_IS_USER_LOGIN";
const SET_SIDEBAROPEN = "SET_SIDEBAROPEN";
const USER_INFO = "USER_INFO";

const setIsUserLogin = (data) => {
  return {
    type: SET_IS_USER_LOGIN,
    payload: data,
  };
};
const setSideBarOpen = (data) => {
  return {
    type: SET_SIDEBAROPEN,
    payload: data,
  };
};
const setUserInfo = (data) => {
  return {
    type: USER_INFO,
    payload: data,
  };
};

export {
  setIsUserLogin,
  setSideBarOpen,
  setUserInfo,
  SET_IS_USER_LOGIN,
  SET_SIDEBAROPEN,
  USER_INFO,
};
