import React, { useEffect, useState } from "react";
import FollowupCard from "./FollowupCard";
import { gql, useQuery } from "@apollo/client";
import PiplineCTA from "../../StyledComponent/PiplineCTA";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddFollowupDialog from "./AddFollowupDialog";
import Toast from "../../Toast/Toast";

const FetchAllFollowup = gql`
  query FetchAllFollowup($lead_id: BigInt) {
    FetchAllFollowup(lead_id: $lead_id) {
      id
      text
      follow_up_at
      is_done
      is_missed
      is_upcomming
      status
      done_at
    }
  }
`;

export default function Followup({ id, onClose }) {
  const {
    data: followups,
    loading,
    refetch,
  } = useQuery(FetchAllFollowup, {
    variables: {
      lead_id: id,
    },
  });

  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  useEffect(() => {
    if (true) {
      if (followups) {
        console.log("RD Followups", followups);
        setData(followups?.FetchAllFollowup);
      }
    }
  }, [followups]);

  if (loading) return <p>Loading...</p>;
  return (
    <div className="rd-followup">
      <div className="topbar">
        <h1>Followup</h1>
        <Button
          size="small"
          onClick={() => setOpenDialog(true)}
          variant="contained"
          color="primary"
        >
          Add Followup
        </Button>
        {/* <PiplineCTA
          component={Link}
          to={`/pipeline?id=${3}`}
          onClick={() => onClose()}
        /> */}
      </div>
      <div className="card-container">
        {data.map((data, index) => (
          <FollowupCard
            data={data}
            key={index}
            refetch={() => {
              refetch();
            }}
          />
        ))}
      </div>
      <AddFollowupDialog
        lead_id={id}
        open={openDialog}
        refetch={() => {
          refetch();
        }}
        setToast={(e) => setToast(e)}
        onClose={() => setOpenDialog(false)}
      />

      <Toast
        data={toast}
        handleClose={() => {
          setToast({
            open: false,
            message: "",
            isSuccess: false,
          });
        }}
        open={toast.open}
      />
    </div>
  );
}
