import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function ThemeLoader() {
  return (
    <div
      style={{
        height: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
}
