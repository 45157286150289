import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./Header";
import SearchBox from "./SearchBox";
import UserCard from "./UserCard";
import FilterBar from "./FilterBar";
import Loader from "./Loader";
import MiniLoader from "../../../component/Loader/MiniLoader";

export default function UserList({
  loading,
  search,
  setSearch,
  unReadFilter,
  setUnreadFilter,
  isNext,
  list,
  selected,
  setSelected,
  handleScrollDown,
}) {
  return (
    <div className="chat-screen-user-list">
      <Header />
      <SearchBox search={search} setSearch={setSearch} />
      <FilterBar
        unReadFilter={unReadFilter}
        setUnreadFilter={setUnreadFilter}
      />

      <div id="scrollableDiv" className="chat-screen-user-list-cards-container">
        {loading || list?.length === 0 ? (
          <Loader loading={loading} empty={list?.length === 0 ? true : false} />
        ) : (
          <InfiniteScroll
            style={{ width: "100%", height: "inherit" }}
            loader={<MiniLoader />}
            dataLength={list?.length}
            hasMore={isNext}
            scrollableTarget="scrollableDiv"
            next={handleScrollDown}
          >
            {list?.map((data, index) => (
              <UserCard
                data={data}
                key={index}
                index={index}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
