import React, { useState, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import SideDrawer from "./SideDrawer";
import Card from "./Card";
import { useQuery, gql } from "@apollo/client";
import CancelOrderModal from "./CancelOrderModal";
import ChangeProviderModal from "./ChangeProviderModal";
import MiniLoader from "../../component/Loader/MiniLoader";
import TheEndCard from "./TheEndCard";
import Toast from "../../component/Toast/Toast";
import ManageDeliveryTopBar from "./ManageDeliveryTopbar";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ManageDelivery() {
  const [isNext, setIsNext] = useState(false);
  const [ongoing, setOngoing] = useState(false);

  // window.onscroll = function (ev) {
  //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //     if (isNext && !ongoing) {
  //       setPage(page + 1);
  //       setOngoing(true);
  //     }
  //   }
  // };

  const [page, setPage] = useState(1);
  // const [toatalPage, setTotalPage] = useState(0);
  // const [totalEntries, setTotalEntries] = useState(0);

  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");
  const [status, setStatus] = useState("ALL"); //
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL");

  const FetchDeliveryOrders = gql`
    query {
      FetchDeliveryOrders(page_no: ${page} search_on:"${searchBy}" sort_by:"${sortBy}" order_status:"${status}" search_query:"${search}") {
        is_next_page
        orders {
          timestamp
          ref_order_id
          order_no
          f_order_delivery {
            status
            estimated_pickup_at
          }
          status
          restaurant_details {
            address
            name
            mobile
          }
          client_details {
            name
            address
            mobile
          }
          rider_details {
            name
            mobile
          }
        }
      }
    }
  `;

  const { loading, error, data, refetch } = useQuery(FetchDeliveryOrders);

  const [orders, setOrders] = useState([]);
  const [loadingStatus, setShowLoadingStatus] = useState(true);

  useEffect(() => {
    refetch();
    if (data) {
      console.log("data", data);
      // if (data.FetchDeliveryOrders.is_next_page === true) {
      //   setShowLoadingStatus(true);
      // } else if (data.FetchDeliveryOrders.is_next_page === false) {
      //   setShowLoadingStatus(false);
      // }
      setIsNext(data.FetchDeliveryOrders.is_next_page);
      setOrders([...orders, ...data.FetchDeliveryOrders.orders]);
    }
  }, [data]);

  const [open, setOpen] = useState({
    open: false,
    ref_order_id: undefined,
  });

  const [cancelModal, setCancelModal] = useState(false);
  const [changeProvider, setchangeProvider] = useState(false);
  const [deliveryProvider, setDeliveryProvider] = useState("");
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });
  const [reload, setReload] = useState(false);

  return (
    <div>
      <main>
        <ManageDeliveryTopBar
          search={search}
          setSearch={(e) => {
            setPage(1);
            setOrders([]);
            setSearch(e);
          }}
          searchBy={searchBy}
          setsearchBy={(e) => {
            setPage(1);
            setOrders([]);
            setsearchBy(e);
          }}
          sortBy={sortBy}
          setSortBy={(e) => {
            setPage(1);
            setOrders([]);
            setSortBy(e);
          }}
          status={status}
          setStatus={(e) => {
            setPage(1);
            setOrders([]);
            setStatus(e);
          }}
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
          storeType={storeType}
          setStoreType={(e) => setStoreType(e)}
          add={(e) => console.log("add")}
          refetch={() => refetch()}
        />
        <InfiniteScroll
          loader={<MiniLoader />}
          dataLength={orders?.length}
          hasMore={isNext}
          next={() => setPage(page + 1)}
          endMessage={data && <TheEndCard />}
        >
          {orders.map((data) => (
            <Card data={data} key={data?.id} handleOpen={(e) => setOpen(e)} />
          ))}
        </InfiniteScroll>
        <Drawer
          open={open.open}
          onClose={() =>
            setOpen({
              open: false,
              ref_order_id: undefined,
            })
          }
          anchor={"right"}
        >
          <SideDrawer
            handleCancelOrderModal={(e) => setCancelModal(e)}
            handleChangeProviderModal={(e) => setchangeProvider(e)}
            setDeliveryProvider={(e) => setDeliveryProvider(e)}
            ref_order_id={open.ref_order_id}
            reload={reload}
            handleClose={() =>
              setOpen({
                open: false,
                ref_order_id: undefined,
              })
            }
          />
        </Drawer>
        <CancelOrderModal
          handleCancelOrderModal={(e) => setCancelModal(e)}
          open={cancelModal}
          ref_order_id={open.ref_order_id}
          setToast={(e) => setToast(e)}
        />
        <ChangeProviderModal
          open={changeProvider}
          ref_order_id={open.ref_order_id}
          deliveryProvider={deliveryProvider}
          handleChangeProviderModal={(e) => {
            setchangeProvider(e);
            setReload(!reload);
          }}
          setToast={(e) => setToast(e)}
        />

        <Toast
          data={toast}
          handleClose={() => {
            setToast({
              open: false,
              message: "",
              isSuccess: false,
            });
          }}
          open={toast.open}
        />
      </main>
    </div>
  );
}
