import React from "react";
import InfoCard from "../../component/InfoCard";
const merChantImg = window.location.origin + "/assets/ReportMerchant.svg";
const unattandedBig =
  window.location.origin + "/assets/followup/unattendedBig.svg";
const missed = window.location.origin + "/assets/followup/missedBig.svg";
const upcomming = window.location.origin + "/assets/followup/upcommingBig.svg";
const actionRequired =
  window.location.origin + "/assets/followup/actionRequiredBig.svg";

export default function CardContiner({ summary }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard
        count={summary?.unattended}
        title={"Unattended"}
        img={unattandedBig}
      />
      <InfoCard count={summary?.missed} title={"Missed"} img={missed} />
      <InfoCard count={summary?.upcomming} title={"Upcoming"} img={upcomming} />

      <InfoCard
        count={summary?.action_required}
        title={"Action Required"}
        img={actionRequired}
      />
    </div>
  );
}
