import React from "react";
import { FiMoreVertical } from "react-icons/fi";
export default function Header() {
  return (
    <div className="header">
      <img src={"https://picsum.photos/200"} alt="" />
      <FiMoreVertical className="more" />
    </div>
  );
}
