import React from "react";

export default function InfoCard({ title, img, count, style }) {
  return (
    <div style={style} className="info-card">
      <p>{title}</p>
      <div>
        <img src={img} alt="" />
        <h1>{count}</h1>
      </div>
    </div>
  );
}
