import React, { useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import Api from "../../Core/Api";

const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";
const rejected = window.location.origin + "/assets/Verification/cancel.svg";
const verified = window.location.origin + "/assets/Verification/check.svg";
const underReivew =
  window.location.origin + "/assets/Verification/underReviewSmall.svg";
const pending =
  window.location.origin + "/assets/Verification/pendingSmall.svg";

const activated = window.location.origin + "/assets/Verification/smallBlue.svg";
const readytoactive =
  window.location.origin + "/assets/Verification/smallGreen.svg";
const onboarding =
  window.location.origin + "/assets/Verification/smallYellow.svg";
const documentStatus = (data) => {
  switch (data) {
    case "REJECTED":
      return (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img style={{ marginRight: "10px" }} src={rejected} alt="icon" />{" "}
          <>Rejected</>
        </div>
      );
    case "VERIFIED":
      return (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img style={{ marginRight: "10px" }} src={verified} alt="icon" />{" "}
          <>Verified</>
        </div>
      );
    case "PENDING":
      return (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img style={{ marginRight: "10px" }} src={pending} alt="icon" />{" "}
          <>Pending</>
        </div>
      );
    case "UNDER_REVIEW":
      return (
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <img style={{ marginRight: "10px" }} src={underReivew} alt="icon" />{" "}
          <>Under Review</>
        </div>
      );

    default:
      return <>{data}</>;
  }
};

const activationStatus = (data) => {
  switch (data) {
    case "ACTIVATED":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ marginRight: "10px" }} src={activated} alt="icon" />{" "}
          <>Activated</>
        </div>
      );
    case "ONBOARDING":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ marginRight: "10px" }} src={onboarding} alt="icon" />{" "}
          <>Onboarding</>
        </div>
      );
    case "READY_TO_ACTIVE":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ marginRight: "10px" }} src={readytoactive} alt="icon" />{" "}
          <>Ready to Active</>
        </div>
      );

    default:
      return <>...</>;
  }
};

export default function Card({ row, setToast, refresh }) {
  const [process, setProcess] = useState(false);
  const [showSave, setShowSave] = useState(false);

  useEffect(() => {
    setProcess(false);
    setShowSave(false);
  }, [row]);

  const downloadFile = (url) => {
    console.log(url);
    window.open(url, "_blank");
  };
  const uploadExcelMenu = (e) => {
    setProcess(true);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log(e.target.files[0]);
      var data = new FormData();
      data.append("excel_menu", file);
      data.append("lead_id", row.lead_id);
      Api.excelMenuUpload(data)
        .then(function (response) {
          console.log("response", response.data.data);
          if (response.data.data.is_menu_valid === false) {
            downloadFile(response.data.data.excel_url);
          } else if (response.data.data.is_menu_valid === true) {
            setProcess(false);
            setShowSave(true);
          } else {
            setProcess(false);
            setShowSave(false);
            setToast({
              open: true,
              message: "Something went Wrong",
              isSuccess: false,
            });
          }
        })
        .catch(function (error) {
          console.log("Error while uploading", error);
          setProcess(false);
          setShowSave(false);
          setToast({
            open: true,
            message: "Something went Wrong",
            isSuccess: false,
          });
        });
    } else {
      console.log("No file choosed");
    }
  };

  const save = () => {
    var qs = require("qs");
    setProcess(true);
    var data = qs.stringify({
      lead_id: row.lead_id,
    });
    Api.save(data)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        if (response.data.is_menu_valid || response.data.status_code === 200) {
          setShowSave(false);
          setProcess(false);
          refresh();
          setToast({
            open: true,
            message: "Uploaded!",
            isSuccess: true,
          });
        } else if (response.data.status === "Failed") {
          setToast({
            open: true,
            message: response?.data?.error_message,
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {row.restaurant_name}
      </TableCell>
      <TableCell align="center">
        {row?.store_type === "RESTAURANT" ? (
          <img src={cutlery} alt="" />
        ) : (
          <img src={shoppingcart} alt="" />
        )}
      </TableCell>
      <TableCell style={{ color: "#888888" }} align="left">
        {moment.unix(row?.created_at).format("DD MMM YYYY")}
      </TableCell>

      <TableCell align="center">
        {documentStatus(row?.document_status)}
      </TableCell>
      <TableCell align="right">{activationStatus(row?.status)}</TableCell>
      <TableCell align="center">
        {row.is_menu_uploaded ? "Yes" : "No"}
      </TableCell>
      <TableCell align="center">
        <input
          style={{ display: "none" }}
          id={`contained-button-file${row.lead_id}`}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => uploadExcelMenu(e)}
        />
        <label htmlFor={`contained-button-file${row.lead_id}`}>
          {showSave === true ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => save()}
            >
              {process ? (
                <CircularProgress color="inherit" size={20} thickness="5" />
              ) : (
                "Save"
              )}
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              component="span"
            >
              {process ? (
                <CircularProgress color="inherit" size={20} thickness="5" />
              ) : (
                "Upload "
              )}
            </Button>
          )}
        </label>
      </TableCell>
    </TableRow>
  );
}
