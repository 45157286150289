import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  FormControl,
  Toolbar,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import DatePicker from "react-date-picker";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {
    flexGrow: 2,
    padding: theme.spacing(3),
  },
}));

function TopBar({ setSearch, selected, setSelected, searchOn, setSearchOn }) {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };
  const handleSearchOn = (event) => {
    setSearchOn(event.target.value);
  };

  return (
    <Toolbar
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0px",
      }}
    >
      <h1
        style={{
          fontSize: "42px",
          fontWeight: "500",
          lineHeight: "53.76px",
        }}
      >
        Overview
      </h1>

      {/* <TextField
        id="outlined-basic"
        label="search"
        variant="outlined"
        onChange={(e) => setSearch(e.target.value)}
        autoFocus
      /> */}

      {/* <FormControl style={{ minWidth: "150px" }} variant="filled">
        <InputLabel>{"Select"}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchOn}
          onChange={handleSearchOn}
        >
          <MenuItem value={"ALL"}>All</MenuItem>
          <MenuItem value={"ORDER_ID"}>ORDER ID</MenuItem>
          <MenuItem value={"MERCHANT_NUMBER"}>MERCHANT NUMBER</MenuItem>
        </Select>
      </FormControl> */}
      {/* <FormControl style={{ minWidth: "150px" }} variant="filled">
        <InputLabel>{"Schedule"}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value={"ALL"}>All</MenuItem>
          <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
        </Select>
      </FormControl> */}
    </Toolbar>
  );
}

export default TopBar;
