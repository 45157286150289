import React from "react";
import { Tooltip } from "@material-ui/core";

export default function UserName({ fname, lname }) {
  const getName = () => {
    if (fname && lname) {
      return fname[0] + lname[0].toUpperCase();
    }
    if (fname) {
      return fname[0];
    } else {
      return "";
    }
  };

  const getTitle = () => {
    if (fname && lname) {
      return fname + " " + lname;
    }
    if (fname) {
      return fname;
    } else {
      return "";
    }
  };

  return (
    <Tooltip title={`${getTitle()}`}>
      <div
        style={{
          backgroundColor: "skyblue",
          color: "white",
          borderRadius: "50%",
          width: "35px",
          cursor: "pointer",
          height: "35px",
          display: "flex",
          fontSize: "12px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>{getName()}</span>
      </div>
    </Tooltip>
  );
}
