import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { gql, useQuery } from "@apollo/client";
import Api from "./../../../Core/Api";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
} from "@material-ui/core";

const FetchAllRestaurantFilters = gql`
  query {
    FetchAllRestaurantFilters {
      status
      tags
    }
  }
`;

export default function Tags({ data, lead_id, refetch }) {
  const {
    loading,
    error,
    data: fetchTags,
  } = useQuery(FetchAllRestaurantFilters);
  const [localtags, setLocalTags] = useState([]);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showCircularProcess, setShowCircularProcess] = useState(false);
  const [newtag, setnewTag] = useState("");

  const addTag = () => {
    if (fetchTags) {
      setLocalTags(fetchTags.FetchAllRestaurantFilters.tags);
      setshowDropDown(true);
    }
  };

  const addNewTag = (tag) => {
    setnewTag(tag);
    console.log(tag);
    setShowCircularProcess(true);
    const temp = {
      lead_id: lead_id,
      tags: [...data, { text: tag }],
    };

    Api.addTag(temp)
      .then((response) => {
        console.log(response.data);
        refetch();
        setShowCircularProcess(false);
        // setshowDropDown(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeTag = (index) => {
    const localArray = [...data];
    setShowCircularProcess(true);
    const temp = {
      lead_id: lead_id,
      tags: localArray.filter((data, i) => i != index),
    };
    console.log("data Sent ", temp);
    Api.addTag(temp)
      .then((response) => {
        console.log(response.data);
        refetch();
        setShowCircularProcess(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="tags-container">
        {data?.map((data, index) => (
          <div
            key={index}
            className="tag"
            style={{
              backgroundColor: data.color,
            }}
          >
            <p>{data.text}</p>

            <CloseIcon
              onClick={() => removeTag(index)}
              style={{
                color: "red",
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "19px",
              }}
            />
          </div>
        ))}

        <div>
          {!showDropDown ? (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => addTag()}
              style={{
                padding: "0px 0px",
                marginRight: "5px",
                marginBottom: "5px",
                fontSize: "15px",
                border: "none",
                color: "white",
              }}
            >
              ADD
            </Button>
          ) : (
            <FormControl>
              <Select
                value={newtag}
                open={showDropDown}
                onClose={() => setshowDropDown(false)}
                onOpen={() => setshowDropDown(true)}
                onChange={(e) => addNewTag(e.target.value)}
              >
                {localtags.map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {showCircularProcess && <CircularProgress size={30} />}
      </div>
    </div>
  );
}
