import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

export default function CustomRadioGroup({ searchBy, setsearchBy }) {
  return (
    <div className="custom-radio-group">
      <RadioGroup
        row
        aria-label="position"
        name="position"
        value={searchBy}
        disableRipple
        onChange={(e) => {
          setsearchBy(e.target.value);
        }}
      >
        <FormControlLabel
          value="RESTAURANT_NAME"
          control={<Radio disableRipple size="small" color="primary" />}
          label="Name"
          labelPlacement="end"
        />
        <FormControlLabel
          value="MERCHANT_NUMBER"
          control={<Radio disableRipple size="small" color="primary" />}
          label="Number"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
}
