import React from "react";
import moment from "moment";
import PhoneIcon from "@material-ui/icons/Phone";
import { IconButton } from "@material-ui/core";
const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";

export default function BusinessInfo({ data }) {
  return (
    <>
      <div className="business-info-bar">
        <div>
          <p>Brand Name</p>
          <h1> {data?.brand_name}</h1>
        </div>
        <div>
          <p>Created At</p>
          <h1>{moment.unix(data.created_at).format("DD MMM YYYY")}</h1>
        </div>
      </div>
      <div className="business-info-bar">
        <div>
          <p>Store Type</p>
          <h1 style={{ display: "flex", alignItems: "center" }}>
            {data?.store_type === "RESTAURANT" ? (
              <img src={cutlery} alt="" />
            ) : (
              <img src={shoppingcart} alt="" />
            )}
            <span style={{ marginLeft: "10px" }}> {data?.store_type} </span>
          </h1>
        </div>
        <div>
          <p>Created By</p>
          <h1>{data?.created_by}</h1>
        </div>
      </div>
      <div className="business-info-bar">
        <div>
          <p>Assign To</p>
          {data?.assignee && (
            <h1>
              {data?.assignee?.fname + " "}
              {data?.assignee?.lname && <span>{data?.assignee?.lname}</span>}
            </h1>
          )}
        </div>
      </div>
      <div className="business-info-bar">
        <div>
          <p>Business Owner Full Name</p>
          <h1>{data?.owner_name}</h1>
        </div>
      </div>
      <div className="business-info-bar">
        <div>
          <p>Business Owner Contact Number</p>
          <h1>
            {data.owner_mobile && (
              <>
                <a href={`tel: ${data.owner_mobile}`}>
                  <IconButton
                    size="small"
                    style={{ backgroundColor: "#2D9CDB", marginRight: "10px" }}
                  >
                    <PhoneIcon
                      style={{
                        color: "white",
                        fontSize: "20px",
                      }}
                    />
                  </IconButton>
                </a>
                {data.owner_mobile}
              </>
            )}
          </h1>
        </div>
      </div>
    </>
  );
}
