import React, { useState } from "react";
import moment from "moment";
import Api from "./../../../Core/Api";
import { Button, CircularProgress } from "@material-ui/core";

const completedicon = window.location.origin + "/assets/check.svg";
const upcommingicon = window.location.origin + "/assets/loading.svg";
const missed = window.location.origin + "/assets/cross.svg";
export default function FollowupCard({ data, refetch }) {
  const [process, setProcess] = useState(false);
  const markFollowUp = (followup_id) => {
    setProcess(true);
    const data = {
      id: followup_id,
    };

    Api.markFollowUp(data)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          setTimeout(() => {
            setProcess(false);
          }, [1000]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="rd-followup-card">
      <div className="contianer">
        <div className="div1">
          {data.is_done ? (
            <>
              <img src={completedicon} alt="" />
              <h1>On Time</h1>
            </>
          ) : data.is_upcomming ? (
            <>
              <img src={upcommingicon} alt="" />
              <h1>Upcomming</h1>
            </>
          ) : (
            <>
              <img src={missed} alt="" />
              <h1>Missed</h1>
            </>
          )}
        </div>
        <div className="div2">
          <div className="data">
            <p>
              Followup on <br />{" "}
              <span>{moment(data?.follow_up_at).format("DD MMM YYYY")}</span>
            </p>

            <h1 style={{ textAlign: "right" }}>
              {data.is_done
                ? `Completed on ${moment(data.done_at).format("DD MMM YYYY")}`
                : "Not Completed"}
            </h1>
          </div>
          <p className="text">{data.text}</p>
        </div>
      </div>

      {!data.is_done && (
        <Button
          onClick={() => markFollowUp(data.id)}
          fullWidth
          size="small"
          variant="contained"
          color="primary"
        >
          {process ? (
            <CircularProgress color="inherit" size={20} thickness="5" />
          ) : (
            "Mark as Completed"
          )}
        </Button>
      )}
    </div>
  );
}
