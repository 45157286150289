import React from "react";
import InfoCard from "../../component/InfoCard";
const merChantImg = window.location.origin + "/assets/ReportMerchant.svg";
const one = window.location.origin + "/assets/issues/1.svg";
const two = window.location.origin + "/assets/issues/2.svg";
const three = window.location.origin + "/assets/issues/3.svg";
const four = window.location.origin + "/assets/issues/4.svg";
const missed = window.location.origin + "/assets/followup/missedBig.svg";
const upcomming = window.location.origin + "/assets/followup/upcommingBig.svg";
const actionRequired =
  window.location.origin + "/assets/followup/actionRequiredBig.svg";

export default function CardContiner({ summary }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard
        count={summary?.total_issues}
        title={"Total issues"}
        img={one}
      />
      <InfoCard
        count={summary?.total_resolved}
        title={"Resolved issues"}
        img={two}
      />
      <InfoCard
        count={summary?.total_pending_replies}
        title={"Pending Reply"}
        img={three}
      />

      <InfoCard
        count={summary?.total_pending_responses}
        title={"Awaiting Response"}
        img={four}
      />
    </div>
  );
}
