import React, { useState, useEffect } from "react";
import Api from "../../Core/Api";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import SearchBox from "../../component/StyledComponent/SearchBox";
import Title from "../../component/StyledComponent/Title";

const Add = window.location.origin + "/assets/Leads/AddBig.svg";

function ThemeHeader({
  search,
  setSearch,
  catogery,
  setCatogery,
  layout,
  setLayout,
  openDrawer,
}) {
  const [filters, setFilters] = useState({
    categories: [],
    layout_codes: [],
  });

  useEffect(() => {
    Api.getThemeFilter()
      .then((res) => {
        console.log("Filters", res);
        if (res?.data?.status_code) setFilters(res?.data?.data);
      })
      .catch((error) => console.log("Error", error));
  }, []);
  return (
    <div className="theme-header">
      <div className="div1">
        <Title title={"Theme"} />
        <div className="div1-img">
          <div>
            <IconButton>
              <img src={Add} onClick={() => openDrawer()} alt="add" />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="div2">
        <div>
          <SearchBox search={search} setSearch={(e) => setSearch(e)} />
        </div>
        <div>
          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"Catogery"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={catogery}
              onChange={(e) => setCatogery(e.target.value)}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              {filters?.categories?.map((e) => (
                <MenuItem key={e.id} value={e?.id}>
                  {e?.category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"Layout"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={layout}
              onChange={(e) => setLayout(e.target.value)}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"ALL"}>All</MenuItem>

              {filters?.layout_codes?.map((e, index) => (
                <MenuItem key={index} value={e}>
                  {e.replace(/_/g, " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default ThemeHeader;
