import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import { BiSearch } from "react-icons/bi";
export default function SearchBox({ search, setSearch }) {
  return (
    <div className="chat-search">
      <div className="search">
        <div className="icon-container">
          <BiSearch className="search-icon" />
        </div>

        <InputBase
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="base-input"
          placeholder="Search or start new chat"
        />
        <div className="icon-container">{/* <BiSearch /> */}</div>
      </div>
    </div>
  );
}
