import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Drawer from "@material-ui/core/Drawer";
import LeadHeader from "./LeadHeader";
import CreateLead from "./CreateLead";
import CardContiner from "./CardContainer";
import LeadTable from "./LeadTable";
import RightDrawer from "../../component/RightDrawer/RightDrawer";
import Toast from "../../component/Toast/Toast";

export default function Lead() {
  const [page, setPage] = useState(1);
  const [toatalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");

  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL"); //RESTAURANT RETAIL ALL
  const [memberId, setMemberId] = useState(-1);
  const [memberList, setMemberList] = useState([]);

  const [activationStatus, setActivationStatus] = useState("ALL");
  const [documentStatus, setDocumetStatus] = useState("ALL");

  const fetchLead = gql`
    query {
      FetchUnclaimLeads(
        page_no: ${page}
        member_id: ${memberId}
        document_status:"${documentStatus}"
        status:"${activationStatus}"
        search_query: "${search}"
        search_by: "${searchBy}"
        store_type: "${storeType}"
        start_date: ${startDate === "" ? 0 : Date.parse(startDate)}
        end_date: ${endDate === "" ? 0 : Date.parse(endDate)}
        sort_by:"${sortBy}"
      ) {
        current_page
        total_pages
        total_entries
        summary {
          todays_untouch_lead
          pending_leads
          assinged_leads
          activated_leads
        }
        restaurants {
          lead_id
          restaurant_name
          created_at
          status
          store_type
          document_status
          assigned_to {
            fname
            lname
          }
        }
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(fetchLead);

  const [open, setOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [summary, setSummary] = useState("");
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  const [rightDrawer, setRightDrawer] = useState({
    lead_id: null,
    open: false,
  });

  useEffect(() => {
    refetch();
    if (data) {
      console.log("data", data.FetchUnclaimLeads);
      setLeads(data.FetchUnclaimLeads?.restaurants);
      setSummary(data?.FetchUnclaimLeads?.summary);
      setTotalPage(data?.FetchUnclaimLeads?.total_pages);
      setTotalEntries(data?.FetchUnclaimLeads?.total_entries);
    }
  }, [data]);

  console.log("Selected Lead", leads);
  return (
    <div>
      <main>
        <LeadHeader
          search={search}
          setSearch={(e) => setSearch(e)}
          searchBy={searchBy}
          setsearchBy={(e) => setsearchBy(e)}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
          storeType={storeType}
          setStoreType={(e) => setStoreType(e)}
          //
          createLead={() => setOpen(true)}
          refetch={() => refetch()}
          //
          memberId={memberId}
          memberList={memberList}
          setMemberList={(e) => setMemberList(e)}
          setMemberId={(e) => setMemberId(e)}
          //
          activationStatus={activationStatus}
          setActivationStatus={(e) => setActivationStatus(e)}
          documentStatus={documentStatus}
          setDocumetStatus={(e) => setDocumetStatus(e)}
        />

        <CardContiner data={summary} />
        {/* {sortBy} */}
        <LeadTable
          toatalPage={toatalPage}
          totalEntries={totalEntries}
          page={page}
          setPage={(e) => setPage(e)}
          setDrawer={(e) => setRightDrawer(e)}
          data={leads}
          refetch={() => refetch()}
          setToast={(e) => setToast(e)}
        />

        <RightDrawer
          open={rightDrawer?.open}
          index={0}
          id={rightDrawer?.lead_id}
          onClose={() => {
            setRightDrawer({
              open: false,
              lead_id: null,
            });
          }}
        />
        <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <CreateLead
            handleClose={() => {
              refetch();
              setOpen(false);
            }}
            setToast={(e) => setToast(e)}
          />
        </Drawer>
        <Toast
          data={toast}
          handleClose={() => {
            setToast({
              open: false,
              message: "",
              isSuccess: false,
            });
          }}
          open={toast.open}
        />
      </main>
    </div>
  );
}
