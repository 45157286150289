import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { IoMdMailUnread } from "react-icons/io";
export default function FilterBar({ unReadFilter, setUnreadFilter }) {
  return (
    <div className="chat-screen-user-list-filter-bar">
      <div className="unread-filter">
        <IoMdMailUnread className="icon" />
        <Select
          value={unReadFilter}
          onChange={(e) => {
            setUnreadFilter(e.target.value);
          }}
          disableUnderline
        >
          <MenuItem value={"ALL"}>All</MenuItem>
          <MenuItem value={"READ"}>Read</MenuItem>
          <MenuItem value={"UNREAD"}>Unread</MenuItem>
        </Select>
      </div>
    </div>
  );
}
