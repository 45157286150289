import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import StartandEndDate from "../../component/StyledComponent/StartandEndDate";
import RefreshCTA from "../../component/StyledComponent/RefreshCTA";
import { useSelector } from "react-redux";
import SortBy from "../../component/StyledComponent/SortBy";
import Title from "../../component/StyledComponent/Title";
import CustomRadioGroup from "../../component/StyledComponent/RadioGroup";
import SearchBox from "../../component/StyledComponent/SearchBox";
const Res_active = window.location.origin + "/assets/Res_active.svg";
const Res_inactive = window.location.origin + "/assets/Res_inactive.svg";
const Retail_active = window.location.origin + "/assets/Retail_active.svg";
const Retail_inactive = window.location.origin + "/assets/Retail_inactive.svg";
const Add = window.location.origin + "/assets/Leads/AddBig.svg";

function PipeLineTopHeader({
  search,
  setSearch,
  searchBy,
  setsearchBy,
  sortBy,
  setSortBy,
  startDate,
  setStatDate,
  endDate,
  setEndDate,
  storeType,
  setStoreType,
  ///
  refresh,
  addPipeline,
  //
  pipelineId,
  pipelineList,
  setPipelineId,
  //
  memberId,
  memberList,
  setMemberId,
}) {
  const { userInfo } = useSelector((state) => state);

  const [store, setStore] = useState({
    business: true,
    retail: true,
  });
  const handleStore = (e) => {
    console.log(e.target.name);
    const temp = { ...store, [e.target.name]: !store[e.target.name] };
    // console.log("after", temp);
    if (temp.business === false && temp.retail === true) {
      setStoreType("RETAIL");
    } else if (temp.business === true && temp.retail === false) {
      setStoreType("RESTAURANT");
    } else if (temp.business === true && temp.retail === true) {
      setStoreType("ALL");
    } else {
      setStoreType("");
    }
    setStore(temp);
  };

  return (
    <div className="pipeline-header">
      <div className="div1">
        <Title title={"Pipeline"} />
        <div className="div1-img">
          <div style={{ marginRight: "80px" }}>
            <IconButton>
              <img onClick={() => addPipeline()} src={Add} alt="d" />
            </IconButton>
            <RefreshCTA refetch={() => refresh()} />
          </div>
          <div>
            <IconButton>
              <img
                name="retail"
                onClick={(e) => handleStore(e)}
                src={store.retail ? Retail_active : Retail_inactive}
                alt="retail"
              />
            </IconButton>
            <IconButton>
              <img
                name="business"
                onClick={(e) => handleStore(e)}
                src={store.business ? Res_active : Res_inactive}
                alt="business"
              />
            </IconButton>
          </div>
          <SortBy sortBy={sortBy} setSortBy={setSortBy} />
        </div>
      </div>

      <div className="div2">
        <div>
          <SearchBox search={search} setSearch={(e) => setSearch(e)} />

          {userInfo?.is_admin && (
            <FormControl
              style={{
                minWidth: "150px",
                backgroundColor: "white",
                marginLeft: "30px",
              }}
              variant="standard"
            >
              <InputLabel>{"By Member"}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
              >
                {memberList.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    {data.fname}
                  </MenuItem>
                ))}
                <MenuItem value={-1}>ALL</MenuItem>
              </Select>
            </FormControl>
          )}
          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"By PipeLine"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              InputLabelProps={{
                shrink: false,
              }}
              value={pipelineId}
              onChange={(e) => setPipelineId(e.target.value)}
            >
              {pipelineList.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
              {/* <MenuItem value={""}>
                <em>None</em>
              </MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <StartandEndDate
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
        />
      </div>

      <div className="div3">
        <CustomRadioGroup searchBy={searchBy} setsearchBy={setsearchBy} />
      </div>
    </div>
  );
}

export default PipeLineTopHeader;
