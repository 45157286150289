import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  CssBaseline,
  Typography,
  Toolbar,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
  setIsUserLogin,
  setSideBarOpen,
  setUserInfo,
} from "../../store/UserAction";
const fastorLogo = window.location.origin + "/assets/FastorLogo.png";
const logouticon = window.location.origin + "/assets/Logout.jpeg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function Header({ setOpen }) {
  const { sidebarOpen } = useSelector((state) => state);
  const { userInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("pipelineId");
    dispatch(setIsUserLogin(false));
    dispatch(setUserInfo(""));
    history.push("/");
  };
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <div style={{ flexGrow: 1 }}>
        <AppBar color="default" position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={() => {
                dispatch(setSideBarOpen(!sidebarOpen));
              }}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{ flexGrow: 1 }}>
              <span
                style={{
                  fontFamily: "roboto",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "0px",
                }}
              >
                {userInfo?.fname}
                <br></br>
                <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                  Fastor Team App
                </span>
              </span>
            </Typography>

            <img
              src={fastorLogo}
              alt="logo"
              style={{ height: "35px", paddingRight: "20px" }}
            />
            <Button
              startIcon={<img src={logouticon} alt="" />}
              color="inherit"
              onClick={() => logout()}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </>
  );
}
