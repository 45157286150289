import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link, useLocation } from "react-router-dom";
const pipeline = window.location.origin + "/assets/pipeline1.svg";
const pipeline2 = window.location.origin + "/assets/pipeline2.svg";
const verification = window.location.origin + "/assets/verified1.svg";
const verification2 = window.location.origin + "/assets/verified2.svg";
const followup = window.location.origin + "/assets/followup1.svg";
const followup2 = window.location.origin + "/assets/followup2.svg";
const md = window.location.origin + "/assets/Managedelivery1.svg";
const md2 = window.location.origin + "/assets/Managedelivery2.svg";
const lead = window.location.origin + "/assets/lead1.svg";
const lead2 = window.location.origin + "/assets/lead2.svg";
const support = window.location.origin + "/assets/support.svg";
const support2 = window.location.origin + "/assets/support2.svg";
const report = window.location.origin + "/assets/report.svg";
const report2 = window.location.origin + "/assets/report2.svg";
const analytics = window.location.origin + "/assets/analytics.svg";
const analytics2 = window.location.origin + "/assets/analytics2.svg";
const dashboard = window.location.origin + "/assets/dashboard.svg";
const dashboard2 = window.location.origin + "/assets/dashboard2.svg";
const ticket = window.location.origin + "/assets/ticket.svg";
const ticket2 = window.location.origin + "/assets/ticket1.svg";
const conversion = window.location.origin + "/assets/conversion.svg";
const conversion2 = window.location.origin + "/assets/conversion1.svg";
const salesicon = window.location.origin + "/assets/sales.svg";
const salesicon2 = window.location.origin + "/assets/sales1.svg";
const uploadMenu1 = window.location.origin + "/assets/uploadMenu1.svg";
const uploadMenu2 = window.location.origin + "/assets/uploadMenu2.svg";
const operation1 = window.location.origin + "/assets/operation.svg";
const operation2 = window.location.origin + "/assets/operation1.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
}));

export default function ClippedDrawer({ open }) {
  const classes = useStyles();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state);

  const supportList = [
    {
      link: "/ticket",
      text: "Ticket",
      icon: ticket,
      iconActive: ticket2,
      visible: true,
      // visible: userInfo?.is_admin && userInfo?.is_support,
    },
    {
      link: "/manageDelivery",
      text: "Manage Delivery",
      icon: md,
      iconActive: md2,
      visible: true,
    },
  ];

  const sales = [
    {
      link: "/lead",
      text: "Leads",
      icon: lead,
      iconActive: lead2,
      visible: userInfo?.is_admin,
    },
    {
      link: "/pipeline",
      text: "Pipeline",
      icon: pipeline,
      iconActive: pipeline2,
      visible: true,
    },
    {
      link: "/conversion",
      text: "Conversion",
      icon: conversion,
      iconActive: conversion2,
      visible: true,
    },
  ];

  const operations = [
    {
      link: "/verification",
      text: "Verification",
      icon: verification,
      iconActive: verification2,
      visible: userInfo?.is_admin,
    },
    {
      link: "/upload-menu",
      text: "Upload Menu",
      icon: report,
      iconActive: report2,
      visible: true,
    },
  ];

  const menu = [
    {
      link: "/reports",
      text: "Reports",
      icon: uploadMenu1,
      iconActive: uploadMenu2,
      visible: userInfo?.is_admin,
    },
    {
      link: "/",
      text: "Followup",
      icon: followup,
      iconActive: followup2,
      visible: true,
    },
    {
      text: "Sales",
      icon: salesicon,
      iconActive: salesicon2,
      visible: true,
      list: sales,
      open: false,
    },
    {
      text: "Operations",
      icon: operation1,
      iconActive: operation2,
      visible: true,
      list: operations,
      open: false,
    },

    {
      text: "Support",
      icon: support,
      iconActive: support2,
      visible: true,
      list: supportList,
      open: false,
    },
    {
      link: "/chat",
      text: "Chat",
      icon: uploadMenu1,
      iconActive: uploadMenu2,
      visible: userInfo?.is_admin,
    },
    {
      link: "/theme",
      text: "Theme",
      icon: uploadMenu1,
      iconActive: uploadMenu2,
      visible: true,
    },
  ];

  const [arr, setArr] = useState(menu);
  const updateArray = (index) => {
    let temp = [...arr];
    temp[index].open = !temp[index].open;
    setArr(temp);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={open === true && "permanent"}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {arr.map((data, key) => (
              <React.Fragment>
                {data.list ? (
                  <>
                    <ListItem button onClick={() => updateArray(key)}>
                      <ListItemIcon>
                        <img
                          src={
                            data?.list.some(
                              (e) => location.pathname === e.link
                            ) && "#6200EE"
                              ? data?.iconActive
                              : data?.icon
                          }
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color:
                            data?.list.some(
                              (e) => location.pathname === e.link
                            ) && "#6200EE",
                        }}
                        primary={data.text}
                      />
                      {data.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={data.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {data?.list.map((data, key) => (
                          <React.Fragment>
                            <ListItem
                              style={{
                                display: data?.visible ? null : "none",
                              }}
                              className={classes.nested}
                              selected={
                                location.pathname === data.link ? true : false
                              }
                              component={Link}
                              to={data.link}
                              button
                            >
                              <ListItemIcon>
                                <img
                                  src={
                                    location.pathname === data.link
                                      ? data.iconActive
                                      : data.icon
                                  }
                                  alt="icons"
                                />
                              </ListItemIcon>
                              <ListItemText
                                style={{
                                  color:
                                    location.pathname === data.link &&
                                    "#6200EE",
                                }}
                                primary={data.text}
                              />
                            </ListItem>
                          </React.Fragment>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem
                    style={{
                      display: data?.visible ? null : "none",
                    }}
                    selected={location.pathname === data.link ? true : false}
                    button
                    component={Link}
                    to={data.link}
                  >
                    <ListItemIcon>
                      <img
                        src={
                          location.pathname === data.link
                            ? data.iconActive
                            : data.icon
                        }
                        alt="icons"
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        color: location.pathname === data.link && "#6200EE",
                      }}
                      primary={data.text}
                    />
                  </ListItem>
                )}
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
}
