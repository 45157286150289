import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import TopBar from "./TopBar";
import CardContiner from "./CardContiner";

const bechoLogo = window.location.origin + "/assets/BechoLogo.png";
const fastorLogo = window.location.origin + "/assets/FastorLogo.png";

const fetchRecord = gql`
  query {
    FetchAdminDashboard {
      total_cities
      total_retails
      total_merchants
      total_restaurants
    }
  }
`;

export default function Reports() {
  const { data, loading, error, refetch } = useQuery(fetchRecord);
  const [record, setRecord] = useState("");

  useEffect(() => {
    refetch();
    if (data) {
      console.log("data", data);
      setRecord(data?.FetchAdminDashboard);
    }
    return () => {
      setRecord("");
    };
  }, [data]);

  return (
    <div>
      <main>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginBottom: "20px",
            marginTop: "20px",
            alignItems: "center",
            padding: "5%",
          }}
        >
          <img src={fastorLogo} alt="logo" style={{ height: "70px" }} />
          <img src={bechoLogo} alt="logo" style={{ height: "100px" }} />
        </div>
        <TopBar />
        <CardContiner record={record} />
        {/* <div className="report-table-container">
          <Table />
        </div> */}
      </main>
    </div>
  );
}
