import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import ChatContainer from "./ChatContainer";
import MessageBar from "./MessageBar";
import Api from "./../../../Core/Api";

export default function ChatPanel({ selected }) {
  const [refresh, setRefresh] = useState(false);
  const [messages, setMessages] = useState([]);
  const msgbox = useRef(null);
  useEffect(() => {
    getUserChat();
  }, [selected, refresh]);

  useEffect(() => {
    handleScroll();
  }, [messages]);

  const handleScroll = () => {
    msgbox?.current?.scrollIntoView({
      // behavior: "smooth",
      // block: "nearest",
    });
  };

  const getUserChat = () => {
    Api.getContactChat(selected?.r_p_id)
      .then((response) => {
        console.log("User Chat", response);
        if (response?.data?.status_code === 200) {
          setMessages(response?.data?.data?.m_whatsapp_msgs);
        }
      })
      .catch((e) => console.log("Error", e));
  };

  return (
    <div className="chat-screen-panel">
      <Header selected={selected} />
      <ChatContainer messages={messages} msgbox={msgbox} />
      <MessageBar
        selected={selected}
        messages={messages}
        setMessages={setMessages}
        refresh={() => setRefresh(!refresh)}
      />
    </div>
  );
}
