import React from "react";
const verified = window.location.origin + "/assets/Verification/check.svg";
const rejected = window.location.origin + "/assets/Verification/cancel.svg";

export default function Info({ data }) {
  return (
    <div className="info">
      <div className="img"></div>
      <div className="status">
        <h1>
          {data.is_menu_uploaded === true ? (
            <img className="disable" src={verified} alt="" />
          ) : (
            <img className="disable" src={rejected} alt="" />
          )}
          Menu Uploaded
        </h1>
        <h1>
          {data.is_menu_verified === true ? (
            <img className="disable" src={verified} alt="" />
          ) : (
            <img className="disable" src={rejected} alt="" />
          )}
          Menu Verified
        </h1>
        <h1>
          {data.is_document_verified === true ? (
            <img className="disable" src={verified} alt="" />
          ) : (
            <img className="disable" src={rejected} alt="" />
          )}
          Document Verifed
        </h1>
        <h1>
          {data.is_restaurant_detail_complete === true ? (
            <img className="disable" src={verified} alt="" />
          ) : (
            <img className="disable" src={rejected} alt="" />
          )}
          Restaurant Info
        </h1>
      </div>
    </div>
  );
}
