import React from "react";

export default function DocList({ data, setImageModal, setPdfModal }) {
  console.log("Doc", data);
  const row = [
    {
      type: "Addhar Card",
      text: data?.id_card_number,
      img: data?.id_card_link,
      ext: data?.id_card_link?.split(".").pop(),
    },
    {
      type: "Pan Card",
      text: data?.pan_card_number,
      img: data?.pan_card_link,
      ext: data?.pan_card_link?.split(".").pop(),
    },
    {
      type: "Cancelled Cheque",
      text: "",
      img: data?.cancel_cheque_link,
      ext: data?.cancel_cheque_link?.split(".").pop(),
    },
    {
      type: "Food Licence",
      text: data?.food_license_number,
      img: data.food_license_link,
      ext: data?.food_license_link?.split(".").pop(),
    },
  ];

  console.log("raw", row);

  const handleOpen = (data) => {
    if (data?.img) {
      if (data?.img.split(".").pop() === "pdf") {
        setPdfModal({
          open: true,
          type: data.type,
          text: data.text,
          pdf: data.img,
        });
      } else {
        setImageModal({
          open: true,
          type: data.type,
          text: data.text,
          img: data.img,
        });
      }
    } else return;
  };

  return (
    <div className="doclist">
      {row?.map((data, key) => (
        <div onClick={() => handleOpen(data)} className="doc" key={key}>
          <div>
            {data.ext === "pdf" ? (
              <div>Pdf</div>
            ) : (
              <img alt="" src={data?.img} />
            )}
          </div>
          <h1>{data?.type}</h1>
        </div>
      ))}
    </div>
  );
}
