import React from "react";
import { Grid, Paper, Typography, Box, Toolbar } from "@material-ui/core";

export default function TheEndCard() {
  return (
    <Paper elevation={5} style={{ margin: "10px" }}>
      <Box p={0}>
        <Toolbar>
          <Typography style={{ flexGrow: 1, fontWeight: "bold" }}>
            {" "}
            The End
          </Typography>
        </Toolbar>
      </Box>
    </Paper>
  );
}
