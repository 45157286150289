import React from "react";
import { TextField, InputAdornment, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
export default function SearchBox({ search, setSearch }) {
  return (
    <div
      style={{
        width: "150px",
        height: "48px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 5px",
      }}
    >
      <SearchIcon />
      <InputBase
        style={{ paddingLeft: "5px" }}
        value={search}
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}
