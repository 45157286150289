import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import Api from "../../../Core/Api";
import ChatBar from "./ChatBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Toast from "../../Toast/Toast";
import UserName from "../../UserName";
import PiplineCTA from "../../StyledComponent/PiplineCTA";
import { Link } from "react-router-dom";

export default function Messenger({ id, showIssue, refresh }) {
  console.log(id);
  const FetchIssue = gql`
    query {
      FetchIssue(id: ${id}) {
        status
        restaurant_lead{
          restaurant_name
          id
        }
        messages {
          text
          created_at
          from
        }
      }
    }
  `;
  let [data, setData] = useState("");
  const {
    loading,
    error: error2,
    data: tdata1,
    refetch,
  } = useQuery(FetchIssue);
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(false);
  const [text, setText] = useState("");
  const [resName, setResName] = useState("");
  const [process, setProcess] = useState(false);
  const [status, setStatus] = useState("");
  const messengerbox = useRef(null);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  useEffect(() => {
    if (tdata1) {
      console.log("Data2 in drawer", tdata1);
      setResName(tdata1.FetchIssue?.restaurant_lead?.restaurant_name);
      let temp = JSON.stringify(tdata1?.FetchIssue?.messages);
      temp = JSON.parse(temp);
      temp.reverse();
      setData(temp);
      setStatus(tdata1?.FetchIssue?.status);
      setLoaded(false);
    }
  }, [tdata1]);

  useEffect(() => {
    refetch();
  }, [id]);

  const handleScroll = () => {
    messengerbox?.current?.scrollIntoView({
      behavior: "smooth",
      // block: "nearest",
    });
  };

  const handleSend = (text) => {
    setError(false);
    if (text.length > 0) {
      console.log(text);
      const data = {
        issue_id: id,
        text: text,
      };
      Api.sendMsg(data)
        .then((response) => {
          console.log(response);
          if (response.data?.status_code === 200) {
            setText("");
            refetch();
            refresh();
            setTimeout(() => {
              handleScroll();
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError(true);
    }
  };

  const handleCloseIssue = () => {
    setProcess(true);
    Api.CloseIssue({
      id: id,
    })
      .then((response) => {
        console.log(response);
        if (response.data?.status_code === 200) {
          setProcess(false);
          // showIssue();
          refetch();
          setTimeout(() => {
            refresh();
          }, [2000]);
          setToast({
            open: true,
            message: "Issue Closed!",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch((error) => console.log("Error", error));
  };

  const setcolor = (data) => {
    switch (data) {
      case "OPEN":
        return {
          backgroundColor: "#3AD6FF",
          color: "white",
        };
      case "CLOSE":
        return { backgroundColor: "#FE9B6E", color: "white" };
      default:
        return;
    }
  };
  const setIssueStatus = (data) => {
    switch (data) {
      case "OPEN":
        return "Open";
      case "CLOSE":
        return "This issue is Closed";
      default:
        return;
    }
  };
  if (loaded) return <p>Loading...</p>;
  return (
    <div className="rd-messenger">
      <div className="topbar">
        <IconButton onClick={() => showIssue()} edge="start">
          <ArrowBackIcon />
        </IconButton>
        <h1>Issues</h1>
        <h1>{status}</h1>
        <p style={setcolor(status)}> {setIssueStatus(status)}</p>

        {status === "OPEN" && (
          <Button
            onClick={() => handleCloseIssue()}
            variant="contained"
            color="secondary"
            size="small"
          >
            {process ? (
              <CircularProgress color="inherit" size={20} thickness="5" />
            ) : (
              "Close Issue"
            )}
          </Button>
        )}
        {/* <PiplineCTA
          component={Link}
          to={`/pipeline?id=${2}`}
          onClick={() => onClose()}
        /> */}
      </div>

      <div className="msg-container">
        {data?.map((data, index) => (
          <div
            key={index}
            className={data?.from !== "TEAM" ? "team-message" : "user-message"}
          >
            <div className="user-icon">
              <UserName fname={data?.from} lname={null} />
            </div>
            <div className="msg">
              <h1>{moment(data?.created_at).format("DD MMM YY hh : mm a")}</h1>
              <p> {data.text} </p>
            </div>
          </div>
        ))}
        <div ref={messengerbox}></div>
      </div>

      <ChatBar
        text={text}
        status={status}
        setText={(e) => setText(e)}
        sendText={(e) => handleSend(e)}
      />

      <Toast
        data={toast}
        handleClose={() => {
          setToast({
            open: false,
            message: "",
            isSuccess: false,
          });
        }}
        open={toast.open}
      />
    </div>
  );
}
