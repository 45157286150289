import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  FormControl,
  Toolbar,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import Title from "../../component/StyledComponent/Title";
const storeImg = window.location.origin + "/assets/ReportStore.svg";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {
    flexGrow: 2,
    padding: theme.spacing(3),
  },
}));

function ConversionHeader({
  search,
  setSearch,
  searchBy,
  setsearchBy,
  sortBy,
  setSortBy,
  status,
  setStatus,
  startDate,
  setStatDate,
  endDate,
  setEndDate,
  storeType,
  setStoreType,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <div className="conversion-header">
      <div className="div1">
        <div>
          <Title title={"Conversion"} />
          <TextField
            value={search}
            label="search"
            style={{ width: "90%", backgroundColor: "white" }}
            variant="standard"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={searchBy}
            onChange={(e) => {
              setsearchBy(e.target.value);
            }}
          >
            <FormControlLabel
              value="RESTAURANT_NAME"
              control={<Radio color="primary" />}
              label="Name"
              labelPlacement="end"
            />
            <FormControlLabel
              value="MERCHANT_NUMBER"
              control={<Radio color="primary" />}
              label="Number"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
      </div>

      <div className={"div2"}>
        <div>
          <FormControl
            style={{ minWidth: "150px", backgroundColor: "white" }}
            variant="standard"
          >
            <InputLabel>{"Sort By"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortBy}
              onChange={(e) => {
                setSortBy(e.target.value);
              }}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"MARCH_2021"}>March 21 </MenuItem>
              <MenuItem value={"APRIL_2021"}>April 21</MenuItem>
              <MenuItem value={"MAY_2021"}>May 21</MenuItem>
              <MenuItem value={"JUNE_2021"}>June 21</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default ConversionHeader;
