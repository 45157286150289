import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import InfiniteScroll from "react-infinite-scroll-component";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import MiniLoader from "../../component/Loader/MiniLoader";
const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";

const unattanded = window.location.origin + "/assets/followup/unattended.svg";
const missed = window.location.origin + "/assets/followup/missed.svg";
const upcomming = window.location.origin + "/assets/followup/upcomming.svg";
const actionRequired =
  window.location.origin + "/assets/followup/actionRequired.svg";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
  },
});

export default function CustomPaginationActionsTable({
  data,
  setLeadId,
  isNext,
  setPage,
  page,
}) {
  const classes = useStyles2();

  const status = (data) => {
    switch (data) {
      case "ACTION_REQUIRED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={actionRequired}
              alt="icon"
            />{" "}
            <>Action Requierd</>
          </div>
        );
      case "UNATTENDED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={unattanded} alt="icon" />{" "}
            <>Unattended</>
          </div>
        );
      case "MISSED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={missed} alt="icon" />{" "}
            <>Missed</>
          </div>
        );
      case "UPCOMMING":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={upcomming} alt="icon" />{" "}
            <>Upcomming</>
          </div>
        );

      default:
        return <>{data}</>;
    }
  };
  return (
    <Paper>
      <Table
        size="small"
        className={classes.table}
        aria-label="custom pagination table"
      >
        <TableHead style={{ width: "100%" }}>
          <TableRow
            style={{
              backgroundColor: "rgba(98, 0, 238, 0.12)",
              width: "100%",
              display: "flex",
            }}
          >
            <TableCell style={{ fontWeight: "bold", width: "30%" }}>
              {"Store Name"}
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", width: "20%" }}
            >
              {"Business"}
            </TableCell>
            <TableCell
              align="center"
              style={{ fontWeight: "bold", width: "25%" }}
            >
              {"Followup Date"}
            </TableCell>
            <TableCell
              align="left"
              style={{ fontWeight: "bold", width: "25%" }}
            >
              {"Followup Status"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: "100%" }}>
          <InfiniteScroll
            style={{ width: "100%" }}
            loader={<MiniLoader />}
            dataLength={data?.length}
            hasMore={isNext}
            next={() => setPage(page + 1)}
          >
            {data?.map((row, rowindex) => (
              <TableRow
                style={{
                  display: "flex",
                  width: "100%",
                }}
                key={rowindex}
                onClick={() => setLeadId(row?.lead_id)}
              >
                <TableCell style={{ width: "30%" }}>
                  {row?.restaurant_lead?.restaurant_name}
                </TableCell>
                <TableCell style={{ width: "20%" }} align="center">
                  {row?.restaurant_lead?.store_type === "RESTAURANT" ? (
                    <img src={cutlery} alt="" />
                  ) : (
                    <img src={shoppingcart} alt="" />
                  )}
                </TableCell>
                <TableCell style={{ width: "25%" }} align="center">
                  {moment(row?.follow_up_at).format("DD MMMM YYYY")}
                </TableCell>
                <TableCell style={{ width: "25%" }}>
                  <div>{status(row?.status)}</div>
                </TableCell>
              </TableRow>
            ))}
          </InfiniteScroll>
        </TableBody>
      </Table>
    </Paper>
  );
}
