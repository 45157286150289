import { Button, Dialog, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ data, setPdfModal }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Dialog
      open={data.open}
      onClose={() => {
        setPdfModal({
          open: false,
          type: "",
          text: "",
          pdf: null,
        });
      }}
    >
      <div>
        <Document
          file={data?.pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} />
        </Document>

        <div style={{ display: "flex" }}>
          <a
            href={data?.pdf}
            target="_blank"
            rel="noopener noreferrer"
            download={data?.pdf}
          >
            <IconButton color="primary">
              <GetAppIcon />
            </IconButton>
          </a>
          <IconButton
            disabled={pageNumber === 1}
            color="primary"
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <ArrowLeftIcon />
          </IconButton>
          <p>
            Page {pageNumber} of {numPages}
          </p>

          <IconButton
            disabled={pageNumber === numPages}
            color="primary"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <ArrowRightIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}
