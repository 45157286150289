import React, { useState } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  makeStyles,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Checkbox,
  Button,
  Dialog,
} from "@material-ui/core";
import styles from "./LeadDrawer.module.css";
import Api from "../../Core/Api";
const useStyles = makeStyles((theme) => ({
  formControl: {
    // padding: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CreateLead({ handleClose, setToast }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [restaurant_name, setRestaurant_name] = useState(undefined);
  const [contact_person_designation, setcontact_person_designation] =
    useState("BUSINESS_OWNER");

  const [owner_name, setowner_name] = useState(undefined);
  const [owner_mobile, setowner_mobile] = useState(undefined);
  const [contact_person_name, setcontact_person_name] = useState(undefined);
  const [contact_person_mobile, setcontact_person_mobile] = useState(undefined);
  const [buisness_name, setbuisness_name] = useState(undefined);
  const [type, setType] = useState("QSR_TAKEAWAY");
  const [email, setEmail] = useState(undefined);
  const [is_share_with_team, setis_share_with_team] = useState(false);
  const [followup, setFollowup] = useState(undefined);
  const handleChangeCFD = (event) => {
    setcontact_person_designation(event.target.value);
  };
  const handleChangetype = (event) => {
    setType(event.target.value);
  };
  const handleChangeTeam = (event) => {
    setis_share_with_team(!is_share_with_team);
  };

  const createLead = (e) => {
    e.preventDefault();
    var data = {
      restaurant_name: restaurant_name,
      brand_name: "test",
      owner_name: owner_name,
      owner_mobile: owner_mobile,
      contact_person_name: contact_person_name,
      contact_person_mobile: contact_person_mobile,
      contact_person_designation: contact_person_designation,
      buisness_name: buisness_name,
      type: type,
      email: email,
      table_count: 0,
      is_share_with_team: is_share_with_team,
      first_followup: followup,
      // deadline: "sdfsds",
    };

    Api.createNewLead(data)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          setOpen(true);
        } else {
          setToast({
            open: true,
            message: response.data.error_message
              ? response.data.error_message
              : "Something Went Wrong!",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <form onSubmit={(e) => createLead(e)} className={styles.leadDrawer}>
      <div>
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          placeholder="Restaurant Name"
          required
          onChange={(e) => setRestaurant_name(e.target.value)}
          helperText="Ex Mc Donland"
          variant="outlined"
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: "20px", fontWeight: "bold" }}
            component="legend"
          >
            Contact Person's Designation
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={contact_person_designation}
            onChange={handleChangeCFD}
          >
            <FormControlLabel
              value="BUSINESS_OWNER"
              control={<Radio />}
              label="Business Owner"
            />
            <FormControlLabel
              value="MANAGER"
              control={<Radio />}
              label="Manger"
            />
            <FormControlLabel
              value="WAITER"
              control={<Radio />}
              label="Waiter"
            />
            <FormControlLabel
              value="CASHIER"
              control={<Radio />}
              label="Cashier"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          placeholder="Business Owner Full Name"
          onChange={(e) => setowner_name(e.target.value)}
          helperText="Eg. Karan Sood"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          type="number"
          id="filled-helperText"
          placeholder="Business Owner Contact Number"
          onChange={(e) => setowner_mobile(e.target.value)}
          helperText="Eg. 99999999955"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          placeholder="Contacted Person's Full Name"
          onChange={(e) => setcontact_person_name(e.target.value)}
          helperText="If Contacted person is owner, then leave blank"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          type="number"
          id="filled-helperText"
          placeholder="Contacted Person's Phone Number"
          onChange={(e) => setcontact_person_mobile(e.target.value)}
          helperText="If Contacted person is owner, then leave blank"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          placeholder="Restraunt's Registered Business Name"
          onChange={(e) => setbuisness_name(e.target.value)}
          helperText="Eg. Fastor Pvt. Ltd."
          variant="outlined"
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: "20px", fontWeight: "bold" }}
            component="legend"
          >
            Type of Food Place
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="type"
            value={type}
            onChange={handleChangetype}
          >
            <FormControlLabel
              value="QSR_TAKEAWAY"
              control={<Radio />}
              label="QSR Takeway"
            />
            <FormControlLabel
              value="DINE_IN_RESTAURANT"
              control={<Radio />}
              label="Dine in Restaurant"
            />
            <FormControlLabel
              value="CORPORATES"
              control={<Radio />}
              label="Corporates"
            />
            <FormControlLabel
              value="COLLEGES"
              control={<Radio />}
              label="Colleges"
            />
            <FormControlLabel
              value="FOODCOURT"
              control={<Radio />}
              label="Mall Food Court"
            />
            <FormControlLabel
              value="HOSPITALS"
              control={<Radio />}
              label="Hospitals"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Business Email"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          type="number"
          id="filled-helperText"
          placeholder="Location Pin Code"
          variant="outlined"
        />
        <TextField
          style={{ marginTop: "15px", width: "100%" }}
          size="small"
          id="filled-helperText"
          placeholder="Zomato Link"
          variant="outlined"
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={is_share_with_team}
              onChange={handleChangeTeam}
              name="checkedB"
              color="primary"
            />
          }
          label="Share with team"
        />
        <TextField
          style={{ marginTop: "0px", width: "100%" }}
          size="small"
          value={followup}
          onChange={(e) => setFollowup(e.target.value)}
          id="filled-helperText"
          placeholder="Followup Message"
          variant="outlined"
        />
      </div>
      <div
        className={styles.follow_message_container}
        style={{ marginTop: "15px" }}
      >
        <p onClick={() => setFollowup("Followup Message 1")}>
          Followup Message 1
        </p>
        <p onClick={() => setFollowup("Followup Message 2")}>
          Followup Message 2
        </p>
        <p onClick={() => setFollowup("Followup Message 3")}>
          Followup Message 3
        </p>
        <p onClick={() => setFollowup("Followup Message 4")}>
          Followup Message 4
        </p>
      </div>
      <div style={{ marginTop: "10px" }}>
        <form className={classes.container} noValidate>
          <TextField
            style={{ width: "100%" }}
            id="date"
            label="Select Date"
            type="date"
            defaultValue="2017-05-24"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Button
          type="submit"
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
        >
          CREATE
        </Button>
      </div>
      {/* Dialog */}

      <Dialog open={open} onClose={handleClose}>
        <div style={{ padding: "20px" }}>
          <img src={window.location.origin + "/assets/sucess.png"} />

          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Lead created !!
          </h1>
          <Button
            onClick={() => handleClose()}
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </div>
      </Dialog>
    </form>
  );
}
