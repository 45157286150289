import React, { useEffect, useState } from "react";
import ConversionHeader from "./ConversionHeader";
import ConversionTable from "./ConversionTable";
import { gql, useQuery } from "@apollo/client";

export default function Conversion() {
  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("ALL");
  // const [status, setStatus] = useState("ALL");
  // const [startDate, setStatDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [storeType, setStoreType] = useState("ALL");

  const FetchConversion = gql`
  query {
    FetchConversionPipeline (month:"${sortBy}"){
      restaurant_name
      store_type
      paid_on
      package
      months
      paid_amount
      balance
      total_booking_amount
    }
  }
`;

  const [conversions, setConversions] = useState([]);

  const { data, loading, error, refetch } = useQuery(FetchConversion);

  useEffect(() => {
    // refetch();
    if (data) {
      setConversions(data?.FetchConversionPipeline);
    }
  }, [data]);

  return (
    <div>
      <main>
        <ConversionHeader
          search={search}
          setSearch={(e) => setSearch(e)}
          searchBy={searchBy}
          setsearchBy={(e) => setsearchBy(e)}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
          //   status={status}
          //   setStatus={(e) => setStatus(e)}
          //   startDate={startDate}
          //   setStatDate={(e) => setStatDate(e)}
          //   endDate={endDate}
          //   setEndDate={(e) => setEndDate(e)}
          //   storeType={storeType}
          //   setStoreType={(e) => setStoreType(e)}
        />
        {loading ? <p>Loading...</p> : <ConversionTable data={conversions} />}
      </main>
    </div>
  );
}
