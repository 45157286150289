import React, { useEffect, useState } from "react";
import { AppBar, Tab, Box, Typography, Tabs, Drawer } from "@material-ui/core";
import Activity from "./Activity/Activity";
import Followup from "./FollowUp/Followup";
import Details from "./Details/Details";
import Documents from "./Documents/Documents";
import IssueMiddlewere from "./IssueMiddlewere/IssueMiddlewere";
import Plan from "./Plan/Plan";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function RightDrawer({
  open,
  onClose,
  index,
  id,
  issueId,
  refresh,
  pipelineList,
  pipelineId,
}) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(index);
    return () => {
      setValue(index);
    };
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose()}>
      <div className="right-drawer">
        <AppBar variant="fullWidth" position="static">
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={(e, v) => setValue(v)}
          >
            <Tab label="DETAILS" />
            <Tab label="FOLLOW UP" />
            <Tab label="DOCUMENT" />
            <Tab label="Activity" />
            <Tab label="Issue" />
            {/* <Tab label="Plan" /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Details
            refresh={refresh}
            pipelineList={pipelineList}
            pipelineId={pipelineId}
            id={id}
            index={index}
            onClose={() => onClose()}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Followup id={id} onClose={() => onClose()} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Documents open={open} id={id} onClose={() => onClose()} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Activity id={id} onClose={() => onClose()} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <IssueMiddlewere
            refresh={refresh}
            issueId={issueId}
            index={index}
            id={id}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Plan />
        </TabPanel>
      </div>
    </Drawer>
  );
}
