import React from "react";

const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";
const rejected = window.location.origin + "/assets/Verification/cancel.svg";
const verified = window.location.origin + "/assets/Verification/check.svg";
const underReivew =
  window.location.origin + "/assets/Verification/underReviewSmall.svg";
const pending =
  window.location.origin + "/assets/Verification/pendingSmall.svg";

const activated = window.location.origin + "/assets/Verification/smallBlue.svg";
const readytoactive =
  window.location.origin + "/assets/Verification/smallGreen.svg";
const onboarding =
  window.location.origin + "/assets/Verification/smallYellow.svg";

export default function PaymentMehod({ data }) {
  const doctype = () => {
    if (data.payment_method === "upi") {
      return <span>UPI {data.upi_id}</span>;
    }
    if (data.payment_method === "bank") {
      return (
        <span>
          Bank A/C: {data.account_number} IFSC: {data?.ifsc_code}
        </span>
      );
    }
  };

  const documentStatus = (data) => {
    switch (data) {
      case "REJECTED":
        return (
          <h1>
            <img style={{ marginRight: "5px" }} src={rejected} alt="icon" />{" "}
            <>Rejected</>
          </h1>
        );
      case "VERIFIED":
        return (
          <h1>
            <img style={{ marginRight: "5px" }} src={verified} alt="icon" />{" "}
            <>Verified</>
          </h1>
        );
      case "PENDING":
        return (
          <h1>
            <img style={{ marginRight: "5px" }} src={pending} alt="icon" />{" "}
            <>Pending</>
          </h1>
        );
      case "UNDER_REVIEW":
        return (
          <h1>
            <img style={{ marginRight: "5px" }} src={underReivew} alt="icon" />{" "}
            <>Under Review</>
          </h1>
        );

      default:
        return <>{data}</>;
    }
  };

  const activationStatus = (data) => {
    switch (data) {
      case "ACTIVATED":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ marginRight: "10px" }} src={activated} alt="icon" />{" "}
            <>Activated</>
          </div>
        );
      case "ONBOARDING":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ marginRight: "10px" }} src={onboarding} alt="icon" />{" "}
            <>Onboarding</>
          </div>
        );
      case "READY_TO_ACTIVE":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px" }}
              src={readytoactive}
              alt="icon"
            />{" "}
            <>Ready to Active</>
          </div>
        );

      default:
        return <>...</>;
    }
  };

  return (
    <>
      <div className="payment-method">
        <div>
          <p>Addhar Number</p>
          <h1>{data?.id_card_number}</h1>
        </div>
        <div>
          <p>Pan Number</p>
          <h1>{data?.pan_card_number}</h1>
        </div>
      </div>
      <div className="payment-method">
        <div>
          <p>Food Licence</p>
          <h1>{data?.food_license_number}</h1>
        </div>
        <div>
          <p>Payment Method</p>
          <h1>{doctype()}</h1>
        </div>
      </div>
      <div className="payment-method">
        <div>
          <p>Document Status</p>
          <h1>{documentStatus(data?.document_status)}</h1>
        </div>
        <div>
          <p>Status</p>
          <h1>{activationStatus(data?.status)}</h1>
        </div>
      </div>
    </>
  );
}
