import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

export default function SortBy({ sortBy, setSortBy }) {
  return (
    <FormControl
      style={{
        minWidth: "150px",
        backgroundColor: "white",
        marginLeft: "20px",
      }}
      variant="standard"
    >
      <InputLabel>{"Sort By"}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={sortBy}
        onChange={(e) => {
          setSortBy(e.target.value);
        }}
      >
        <MenuItem value={"CREATION_EARLIEST"}>Created Latest</MenuItem>
        <MenuItem value={"CREATION_OLDEST"}>Created Oldest</MenuItem>
        <MenuItem value={"MODIFIED_EARLIEST"}>Updated Latest</MenuItem>
        <MenuItem value={"MODIFIED_OLDEST"}>Updated Oldest</MenuItem>
      </Select>
    </FormControl>
  );
}
