import React from "react";

export default function Info() {
  return (
    <div className="rd-plan-info">
      <div className="item">
        <div>
          <p>Store Name</p>
          <h1>Abc</h1>
        </div>
        <div>
          <p>Deal Status</p>
          <h1>Abc</h1>
        </div>
      </div>
      <div className="item">
        <div>
          <p>Store Type</p>
          <h1>Abc</h1>
        </div>
        <div>
          <p>Created By</p>
          <h1>Abc</h1>
        </div>
      </div>
    </div>
  );
}
