import React, { useState } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import moment from "moment";
import UserName from "./../../component/UserName";
import Api from "../../Core/Api";
import { FormControl, Select, MenuItem } from "@material-ui/core";
const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";

const rejected = window.location.origin + "/assets/Verification/cancel.svg";
const verified = window.location.origin + "/assets/Verification/check.svg";
const underReivew =
  window.location.origin + "/assets/Verification/underReviewSmall.svg";
const pending =
  window.location.origin + "/assets/Verification/pendingSmall.svg";

const activated = window.location.origin + "/assets/Verification/smallBlue.svg";
const lead = window.location.origin + "/assets/Verification/smallRed.svg";
const readytoactive =
  window.location.origin + "/assets/Verification/smallGreen.svg";
const onboarding =
  window.location.origin + "/assets/Verification/smallYellow.svg";

export default function LeadCard({
  setToast,
  row,
  memberList,
  refetch,
  setDrawer,
}) {
  const [memberId, setMemberId] = useState(0);
  const [showDropDown, setshowDropDown] = useState(false);
  const changeLead = (e, lead_id) => {
    setMemberId(e);
    console.log(e);

    var data = {
      lead_id: lead_id,
      member_id: e,
    };
    console.log("Data", data);

    Api.assignleadtoMember(data)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.status_code === 200) {
          setToast({
            open: true,
            message: "Lead Assigned",
            isSuccess: true,
          });
          refetch();
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const documentStatus = (data) => {
    switch (data) {
      case "REJECTED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={rejected} alt="icon" />{" "}
            <>Rejected</>
          </div>
        );
      case "VERIFIED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={verified} alt="icon" />{" "}
            <>Verified</>
          </div>
        );
      case "PENDING":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <>
              <img style={{ marginRight: "10px" }} src={pending} alt="icon" />{" "}
              Pending
            </>
          </div>
        );
      case "UNDER_REVIEW":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <>
              <img
                style={{ marginRight: "10px" }}
                src={underReivew}
                alt="icon"
              />{" "}
              Under Review
            </>
          </div>
        );

      default:
        return <>{data}</>;
    }
  };

  const activationStatus = (data) => {
    switch (data) {
      case "ACTIVATED":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={activated} alt="icon" />{" "}
            <>Activated</>
          </div>
        );
      case "ONBOARDING":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={onboarding} alt="icon" />{" "}
            <>Onboarding</>
          </div>
        );
      case "READY_TO_ACTIVE":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={readytoactive}
              alt="icon"
            />{" "}
            <>Ready to Active</>
          </div>
        );
      case "LEAD":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ marginRight: "10px" }} src={lead} alt="icon" />{" "}
            <>Lead</>
          </div>
        );

      default:
        return <>{data}</>;
    }
  };

  return (
    <TableRow>
      <React.Fragment>
        <TableCell
          onClick={() =>
            setDrawer({
              lead_id: row.lead_id,
              open: true,
            })
          }
          component="th"
          scope="row"
        >
          {row.restaurant_name}
        </TableCell>
        <TableCell
          onClick={() =>
            setDrawer({
              lead_id: row.lead_id,
              open: true,
            })
          }
        >
          {row?.store_type === "RESTAURANT" ? (
            <img src={cutlery} alt="" />
          ) : (
            <img src={shoppingcart} alt="" />
          )}
        </TableCell>
        <TableCell
          onClick={() =>
            setDrawer({
              lead_id: row.lead_id,
              open: true,
            })
          }
          align="right"
        >
          {moment.unix(row.created_at).format("DD MMM YYYY")}
        </TableCell>
      </React.Fragment>
      <TableCell align="right">
        {!showDropDown ? (
          <div
            onClick={() => setshowDropDown(true)}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {row?.assigned_to?.fname ? (
              <UserName
                fname={row?.assigned_to.fname}
                lname={row?.assigned_to?.lname}
              />
            ) : (
              "---"
            )}
          </div>
        ) : (
          <FormControl>
            <Select
              value={memberId}
              open={showDropDown}
              onClose={() => setshowDropDown(false)}
              onOpen={() => setshowDropDown(true)}
              onChange={(e) => changeLead(e.target.value, row.lead_id)}
            >
              {memberList?.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.fname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </TableCell>
      <TableCell
        onClick={() =>
          setDrawer({
            lead_id: row.lead_id,
            open: true,
          })
        }
        align="right"
      >
        {documentStatus(row?.document_status)}
      </TableCell>
      <TableCell
        onClick={() =>
          setDrawer({
            lead_id: row.lead_id,
            open: true,
          })
        }
        align="right"
      >
        <div>{activationStatus(row?.status)}</div>
      </TableCell>
    </TableRow>
  );
}
