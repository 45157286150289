import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import { GrAttachment } from "react-icons/gr";
import { IoMdSend } from "react-icons/io";
import Api from "./../../../Core/Api";

export default function MessageBar({
  selected,
  refresh,
  messages,
  setMessages,
}) {
  const [text, setText] = useState("");

  const sendMsg = (e) => {
    e.preventDefault();

    let temp = {
      from: "TEAM",
      isNew: true,
      message: text,
    };
    let tempMsg = [...messages];
    tempMsg.push(temp);
    setMessages(tempMsg);

    let data = {
      user_id: selected?.r_p_id,
      message: text,
    };
    setText("");
    Api.postContactChat(data)
      .then((response) => {
        console.log("send Msg", response);
        if (response?.data?.status_code === 200) {
          refresh();
        }
      })
      .catch((e) => console.log("error", e));
  };

  return (
    <form onSubmit={(e) => sendMsg(e)}>
      <div className="message-bar">
        <div className="icon-container">
          <GrAttachment className="attach-icon" />
        </div>

        <InputBase
          required
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="base-input"
          placeholder="Type a message"
        />
        <div className="icon-container">
          <IoMdSend
            style={{ color: !text && "#ADADAD" }}
            type="submit"
            onClick={text && sendMsg}
            className="send-icon"
          />
        </div>
      </div>
    </form>
  );
}
