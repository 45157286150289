import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Drawer } from "@material-ui/core";
import PipeLineTopHeader from "./PipelineTopHeader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PipeLineCard from "./PipeLineCard";
import PipeLineTitle from "./PipeLineTitle";
import Api from "../../Core/Api";
import RightDrawer from "../../component/RightDrawer/RightDrawer";
import CreateLead from "../Lead/CreateLead";
import { useLocation } from "react-router-dom";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#FAFAFA",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 200,
  // minWidth: 200,
  minHeight: 400,
  margin: 5,
});

const FetchAllPipelinesList = gql`
  query {
    FetchAllPipelinesList {
      name
      states
      id
    }
    FetchAllUser {
      id
      fname
    }
  }
`;

const FetchAllLeadInPipeline = gql`
  query FetchAllLeadInPipeline(
    $pipeline_id: Int
    $search_query: String
    $sort_by: String
    $search_by: String
    $member_id: Int
    $store_type: String
    $start_date: BigInt
    $end_date: BigInt
  ) {
    FetchAllLeadInPipeline(
      pipeline_id: $pipeline_id
      search_query: $search_query
      search_by: $search_by
      member_id: $member_id
      store_type: $store_type
      start_date: $start_date
      end_date: $end_date
      sort_by: $sort_by
    ) {
      id
      state
      restaurant_lead {
        lead_id
        id
        created_at
        created_by
        updated_at
        store_type
        document_status
        restaurant_name
        brand_name
        owner_name
        owner_mobile
        contact_person_name
        contact_person_mobile
        contact_person_designation
        is_menu_verified
        is_menu_uploaded
        is_document_verified
        is_restaurant_detail_complete
        aadhar_card
        aadhar_number
        pan_card
        pan_card_number
        is_movable
        assigned_to
        assignee {
          fname
          lname
        }
        tags {
          text
          color
        }
      }
      follow_up {
        text
        follow_up_at
        is_done
        is_missed
        is_upcomming
        done_at
      }
    }
  }
`;

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Pipeline() {
  let query = useUrlQuery();
  const urlpipelineid = query.get("id");
  const urlmobileno = query.get("mobile");
  console.log("URL ", query, urlpipelineid, urlmobileno);

  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL"); //

  const [pipelineList, setPipelineList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [pipelineId, setPipelineId] = useState("");
  const [memberId, setMemberId] = useState(-1);
  const [pipelineData, setPipelineData] = useState([]);

  const [open, setOpen] = useState(false);

  const [internalLoading, setInternalLoading] = useState(false);

  const [rightDrawer, setRightDrawer] = useState({
    lead_id: null,
    open: false,
  });

  const { loading: loading2, data: data2 } = useQuery(FetchAllPipelinesList);
  const { loading, error, data, refetch } = useQuery(FetchAllLeadInPipeline, {
    variables: {
      pipeline_id: pipelineId,
      member_id: memberId,
      search_query: search,
      search_by: searchBy,
      store_type: storeType,
      start_date: startDate === "" ? 0 : Date.parse(startDate),
      end_date: endDate === "" ? 0 : Date.parse(endDate),
    },
  });

  useEffect(() => {
    if (data2) {
      console.log("pipeline ", data2);
      setPipelineList(data2.FetchAllPipelinesList);
      setMemberList(data2.FetchAllUser);
      // setPipelineId(parseInt(data2.FetchAllPipelinesList[0].id));
      // setMemberId(0);
    }
  }, [data2]);

  useEffect(() => {
    refetch();
    if (data) {
      console.log("Pipeline data", data);
      setPipelineData(data.FetchAllLeadInPipeline);
      setInternalLoading(true);
    }
  }, [data]);

  useEffect(() => {
    let pipelineId = localStorage.getItem("pipelineId");
    if (pipelineId) {
      setPipelineId(Number(pipelineId));
    }
  }, []);

  useEffect(() => {
    if (urlpipelineid) {
      setPipelineId(Number(urlpipelineid));
      localStorage.setItem("pipelineId", Number(urlpipelineid));
      setSearch(urlmobileno);
      setsearchBy("MERCHANT_NUMBER");
      // setTimeout(() => {
      //   history.push("/pipeline");
      // }, [2000]);
    } else return;
  }, [urlpipelineid]);

  const refetchData = () => {
    setPipelineData([]);
    setInternalLoading(false);
    refetch();
  };

  // console.log("End data", endDate);
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // console.log("Dropable id", draggableId);
    if (draggableId === "0") {
      // console.log("DDDDD");
      return;
    } else if (!destination) {
      // console.log("Droped OutSide ");
      return;
    } else if (source.droppableId === destination.droppableId) {
      // console.log("dropped in same container");
      return;
    } else {
      var data = {
        id: draggableId,
        state: destination.droppableId,
      };
      Api.pipleLineDragandDrop(data)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code === 200) {
            // setRefresh(!refresh);
            refetchData();
          } else {
            // setRefresh(!refresh);
            refetchData();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //***** */

  return (
    <div>
      <main>
        <PipeLineTopHeader
          search={search}
          setSearch={(e) => setSearch(e)}
          searchBy={searchBy}
          setsearchBy={(e) => setsearchBy(e)}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
          storeType={storeType}
          setStoreType={(e) => setStoreType(e)}
          //
          refresh={() => {
            refetch();
            setInternalLoading(true);
          }}
          addPipeline={() => setOpen(true)}
          //
          pipelineId={pipelineId}
          pipelineList={pipelineList}
          setPipelineId={(e) => {
            setPipelineId(e);
            setSearch("");
            setsearchBy("RESTAURANT_NAME");
            localStorage.setItem("pipelineId", e);
          }}
          //
          memberId={memberId}
          memberList={memberList}
          setMemberId={(e) => setMemberId(e)}
        />

        <div className="pipeline-container">
          {/* //Start */}

          {loading ? (
            <p>Loading...</p>
          ) : (
            <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
              {pipelineList[
                pipelineList.findIndex((e) => e.id === pipelineId)
              ]?.states?.map((data, key) => (
                <Droppable key={key} droppableId={data}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <PipeLineTitle pipelineData={pipelineData} data={data} />
                      {internalLoading &&
                        pipelineData
                          .filter((temp, index) => temp.state === data)
                          .map((item, index) => (
                            <Draggable
                              key={index}
                              draggableId={"" + item.id + ""}
                              index={index}
                              isDragDisabled={
                                item.restaurant_lead?.is_movable === true
                                  ? false
                                  : true
                              }
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <PipeLineCard
                                    setDrawer={(e) => setRightDrawer(e)}
                                    item={item}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          )}

          {/* //End */}
        </div>

        <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <CreateLead
            handleClose={() => {
              refetch();
              setInternalLoading(true);
              setOpen(false);
            }}
          />
        </Drawer>

        <RightDrawer
          open={rightDrawer?.open}
          index={0}
          pipelineList={pipelineList}
          pipelineId={pipelineId}
          id={rightDrawer?.lead_id}
          refresh={() => refetch()}
          onClose={() => {
            setRightDrawer({
              open: false,
              lead_id: null,
            });
          }}
        />
      </main>
    </div>
  );
}
