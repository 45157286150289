import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import UserName from "./../../component/UserName";
import CustomPagination from "../../component/CustomPagination";

const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";
const rejected = window.location.origin + "/assets/Verification/cancel.svg";
const verified = window.location.origin + "/assets/Verification/check.svg";
const underReivew =
  window.location.origin + "/assets/Verification/underReviewSmall.svg";
const pending =
  window.location.origin + "/assets/Verification/pendingSmall.svg";

const activated = window.location.origin + "/assets/Verification/smallBlue.svg";
const readytoactive =
  window.location.origin + "/assets/Verification/smallGreen.svg";
const onboarding =
  window.location.origin + "/assets/Verification/smallYellow.svg";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
    marginBottom: "20px",
  },
});

export default function VerificationTable({
  data,
  setLeadId,
  toatalPage,
  page,
  setPage,
  totalEntries,
}) {
  console.log("Verificaton data", data);
  const classes = useStyles2();

  const documentStatus = (data) => {
    switch (data) {
      case "REJECTED":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginRight: "10px" }} src={rejected} alt="icon" />{" "}
            <>Rejected</>
          </div>
        );
      case "VERIFIED":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginRight: "10px" }} src={verified} alt="icon" />{" "}
            <>Verified</>
          </div>
        );
      case "PENDING":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginRight: "10px" }} src={pending} alt="icon" />{" "}
            <>Pending</>
          </div>
        );
      case "UNDER_REVIEW":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ marginRight: "10px" }} src={underReivew} alt="icon" />{" "}
            <>Under Review</>
          </div>
        );

      default:
        return <>{data}</>;
    }
  };

  const activationStatus = (data) => {
    switch (data) {
      case "ACTIVATED":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ marginRight: "10px" }} src={activated} alt="icon" />{" "}
            <>Activated</>
          </div>
        );
      case "ONBOARDING":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img style={{ marginRight: "10px" }} src={onboarding} alt="icon" />{" "}
            <>Onboarding</>
          </div>
        );
      case "READY_TO_ACTIVE":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px" }}
              src={readytoactive}
              alt="icon"
            />{" "}
            <>Ready to Active</>
          </div>
        );

      default:
        return <>...</>;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        className={classes.table}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow style={{ backgroundColor: "rgba(98, 0, 238, 0.12)" }}>
            <TableCell style={{ fontWeight: "bold" }}>{"Store Name"}</TableCell>

            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Business"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Register Date"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Due Date"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Assignee"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Document Status"}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Activation Status"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowindex) => (
            <TableRow key={rowindex} onClick={() => setLeadId(row.lead_id)}>
              <TableCell component="th" scope="row">
                {row.restaurant_name}
              </TableCell>
              <TableCell>
                {row?.store_type === "RESTAURANT" ? (
                  <img src={cutlery} alt="" />
                ) : (
                  <img src={shoppingcart} alt="" />
                )}
              </TableCell>
              <TableCell style={{ color: "#888888" }} align="right">
                {moment.unix(row?.created_at).format("DD MMM YYYY")}
              </TableCell>
              <TableCell align="center">
                {row?.verification_due_at
                  ? moment.unix(row?.verification_due_at).format("DD MMM YYYY")
                  : "N/A"}
              </TableCell>
              <TableCell align="center">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {row?.assignee ? (
                    <UserName
                      fname={row?.assignee?.fname}
                      lname={row?.assignee?.lname}
                    />
                  ) : (
                    "---"
                  )}
                </div>
              </TableCell>
              <TableCell align="center">
                {documentStatus(row?.document_status)}
              </TableCell>
              <TableCell align="right">
                {activationStatus(row?.status)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomPagination
        totalEntries={totalEntries}
        toatalPage={toatalPage}
        page={page}
        setPage={setPage}
      />
    </TableContainer>
  );
}
