import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { gql, useQuery } from "@apollo/client";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Pagination from "@material-ui/lab/Pagination";
import CustomPagination from "../../component/CustomPagination";
import LeadCard from "./LeadCard";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
    marginBottom: "20px",
  },
});

const FetchAllPipelinesList = gql`
  query {
    FetchAllPipelinesList {
      name
      states
      id
    }
    FetchAllUser {
      id
      fname
    }
  }
`;

export default function LeadTable({
  data,
  setDrawer,
  toatalPage,
  page,
  setPage,
  totalEntries,
  refetch,
  setToast,
}) {
  console.log("lead  data", data);
  const classes = useStyles2();

  const { loading, error, data: leadData } = useQuery(FetchAllPipelinesList);
  const [memberList, setMemberList] = useState([]);
  const [memberId, setMemberId] = useState(0);
  const [showDropDown, setshowDropDown] = useState(false);

  useEffect(() => {
    if (leadData) {
      console.log("member list ", leadData);
      setMemberList(leadData?.FetchAllUser);
    }
  }, [leadData]);

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        className={classes.table}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow style={{ backgroundColor: "rgba(98, 0, 238, 0.12)" }}>
            <TableCell style={{ fontWeight: "bold" }}>{"Store Name"}</TableCell>

            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Business"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Register Date"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Assignee"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Document Status"}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              {"Activation Status"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowindex) => (
            <LeadCard
              setDrawer={setDrawer}
              memberList={memberList}
              row={row}
              key={rowindex}
              setToast={(e) => setToast(e)}
              refetch={refetch}
            />
          ))}
        </TableBody>
      </Table>
      <CustomPagination
        totalEntries={totalEntries}
        toatalPage={toatalPage}
        page={page}
        setPage={setPage}
      />
    </TableContainer>
  );
}
