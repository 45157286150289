import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";

const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
  },
});

export default function ConversionTable({ data }) {
  console.log("Data in table", data);
  const classes = useStyles2();

  return (
    <TableContainer>
      <Table
        size="small"
        className={classes.table}
        aria-label="custom pagination table"
      >
        <TableHead>
          <TableRow style={{ backgroundColor: "rgba(98, 0, 238, 0.12)" }}>
            <TableCell style={{ fontWeight: "bold" }}>{"Store Name"}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Business"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Activation Month"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Package"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Duration (mo)"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Paid Amt"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Balance"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Total Booked"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowindex) => (
            <TableRow key={rowindex}>
              <TableCell>{row?.restaurant_name}</TableCell>
              <TableCell align="center">
                {row?.store_type === "RESTAURANT" ? (
                  <img src={cutlery} alt="" />
                ) : (
                  <img src={shoppingcart} alt="" />
                )}
              </TableCell>
              <TableCell style={{ color: "#888888" }} align="center">
                {moment.unix(row.paid_on).format("MMMM")}
              </TableCell>

              <TableCell
                style={{ color: "#888888" }}
                align="center"
              >{`₹ ${row?.package}`}</TableCell>

              <TableCell style={{ color: "#888888" }} align="center">
                {row?.months}
              </TableCell>

              <TableCell style={{ color: "#888888" }} align="center">
                {`₹ ${row?.paid_amount / 100}`}
              </TableCell>

              <TableCell style={{ color: "#888888" }} align="center">{`₹ ${
                row?.balance / 100
              }`}</TableCell>

              <TableCell style={{ color: "#888888" }} align="right">
                {`₹ ${row?.total_booking_amount / 100}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
