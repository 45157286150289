import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

export default function ImageDialog({ imageModal, setImageModal }) {
  const [rotation, setRotation] = useState(0);

  return (
    <div>
      <Dialog
        open={imageModal.open}
        onClose={() => {
          setImageModal({
            open: false,
            type: "",
            text: "",
            img: null,
          });
          setRotation(0);
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {imageModal.type} : {imageModal.text}
            </h2>

            <div>
              <RotateLeftIcon
                onClick={() => {
                  let newRoate = rotation - 90;
                  if (newRoate >= 360) {
                    newRoate = -360;
                  }
                  setRotation(newRoate);
                }}
              />
              <RotateRightIcon
                onClick={() => {
                  let newRoate = rotation + 90;
                  if (newRoate >= 360) {
                    newRoate = -360;
                  }
                  setRotation(newRoate);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: "40px" }}>
            <img
              style={{
                transform: `rotate(${rotation}deg)`,
                width: "40vw",
                height: "70vh",
                objectFit: "contain",
              }}
              src={imageModal.img}
              alt=""
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
