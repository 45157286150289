const axios = require("axios").default;

// const baseURL = "https://staging.fastor.in";
// const baseURL = "https://staging.team.fastor.in";
const baseURL = "https://api.team.fastor.ai";
// const baseURL = "https://api.fastor.ai";

const fastor = axios.create({
  baseURL: baseURL,
});

fastor.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

fastor.interceptors.request.use(function (config) {
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  return config;
});

const Api = {
  login: (data) => {
    return fastor.post("/admin/login", data);
  },
  reject: (data) => {
    return fastor.patch("/admin/lead/document/reject", data);
  },
  verify: (data) => {
    return fastor.patch("/admin/lead/document/verify", data);
  },
  excelMenuUpload: (data) => {
    return fastor.post("/admin/xlsx/upload", data);
  },
  save: (data) => {
    return fastor.post("/admin/xlsx/upload/save", data);
  },
  readytoActive: (data) => {
    return fastor.patch("/admin/lead/status", data);
  },
  pipleLineDragandDrop: (data) => {
    return fastor.patch("/admin/pipeline/lead/state", data);
  },
  createNewLead: (data) => {
    return fastor.post("/admin/lead", data);
  },

  assignleadtoMember: (data) => {
    return fastor.patch("/admin/lead/share/assign", data);
  },
  movePipeLine: (data) => {
    return fastor.patch("/admin/lead/move", data);
  },
  addNewFollowup: (data) => {
    return fastor.post("/admin/followup", data);
  },
  markFollowUp: (data) => {
    return fastor.patch("/admin/followup/done", data);
  },
  addTag: (data) => {
    return fastor.patch("/admin/lead ", data);
  },

  CancelOrder: (data) => {
    return fastor.patch("/admin/support/order/delivery/cancel", data);
  },

  ChangeServiceProvider: (data) => {
    return fastor.patch("/admin/support/order/delivery/change", data);
  },
  sendMsg: (data) => {
    return fastor.post("/admin/issue/message", data);
  },
  createIssue: (data) => {
    return fastor.post("/admin/issue", data);
  },
  CloseIssue: (data) => {
    return fastor.patch("/admin/issue/close", data);
  },
  getContacts: (search, page, unReadFilter) => {
    return fastor.get(
      `/admin/whatsapp/contacts?search_query=${search}&unread_filter=${unReadFilter}&page_no=${page}`
    );
  },
  getContactChat: (id) => {
    return fastor.get(`/admin/whatsapp/contact/chat?user_id=${id}`);
  },
  postContactChat: (data) => {
    return fastor.post(`/admin/whatsapp/contact/chat`, data);
  },
  getTheme: (page, search, catogery, layoutCode) => {
    return fastor.get(
      `/admin/theme/list?search_query=${search}&category_id=${
        catogery === "ALL" ? "" : catogery
      }&layout_code=${layoutCode === "ALL" ? "" : layoutCode}&page_no=${page}`
    );
  },
  getThemeFilter: () => {
    return fastor.get(`/admin/theme/filters`);
  },
  createTheme: (data) => {
    return fastor.post(`/admin/theme`, data);
  },
  updateTheme: (data) => {
    return fastor.patch(`/admin/theme`, data);
  },
};

export default Api;

export { baseURL };
