import React, { useEffect, useState } from "react";
import Card from "./Card";
import { gql, useQuery } from "@apollo/client";
import PiplineCTA from "../../StyledComponent/PiplineCTA";
import { Link } from "react-router-dom";

const fetchActivity = gql`
  query FetchRestaurantActivityLog($lead_id: BigInt) {
    FetchRestaurantActivityLog(lead_id: $lead_id) {
      created_at
      text
      assigned_to {
        fname
        lname
      }
      perform_by {
        fname
        lname
      }
    }
  }
`;

export default function Activity({ id, onClose, refresh }) {
  const [data, setData] = useState([]);
  const {
    data: actity,
    loading,
    refetch,
  } = useQuery(fetchActivity, {
    variables: {
      lead_id: id,
    },
  });

  useEffect(() => {
    if (actity) {
      console.log("Activity", actity);
      setData(actity?.FetchRestaurantActivityLog);
    }
  }, [actity]);

  useEffect(() => {
    refetch();
  }, [id]);
  return (
    <div className="rd-activity">
      <div className="topbar">
        <h1>Activity </h1>
        {/* <PiplineCTA
          component={Link}
          to={`/pipeline?id=${2}`}
          onClick={() => onClose()}
        /> */}
      </div>
      <div className="title-bar">
        <h1>User & Date</h1>
        <h1>Activity</h1>
        <h1>Assign to </h1>
      </div>
      <div className="card-container">
        {data?.map((data, key) => (
          <Card data={data} key={key} />
        ))}
      </div>
    </div>
  );
}
