import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Drawer } from "@material-ui/core";
import SupportTable from "./SupportTable";
import StartandEndDate from "../../component/StyledComponent/StartandEndDate";
import CreateIssue from "./CreateIssue";
import Toast from "../../component/Toast/Toast";
import RightDrawer from "../../component/RightDrawer/RightDrawer";
import SupportHeader from "./SupportHeader";
import CardContiner from "./CardContiner";

export default function Support() {
  const [page, setPage] = useState(1);
  const [toatalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");
  const [status, setStatus] = useState("ALL"); //
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL");

  const FetchAllRestaurantIssues = gql`
    query {
      FetchAllRestaurantIssues(
        page_no: ${page}
        search_query: "${search}"
        search_by: "${searchBy}"
        issue_status: "${status}"
        store_type: "${storeType}"
        sort_by:"${sortBy}"
        start_date: ${startDate === "" ? 0 : Date.parse(startDate)}
        end_date: ${endDate === "" ? 0 : Date.parse(endDate)}
      ) {
        total_pages
        total_entries
        summary {
          total_issues
          total_resolved
          total_pending_replies
          total_pending_responses
        }
        issues {
          id
          type
          status
          from
          created_at
          last_message_at
          priority
          full_status
          restaurant_lead {
            restaurant_name
            store_type
            id
          }
          messages {
            text
            created_at
          }
          resolved_by {
            fname
            lname
            image
          }
        }
      }
    }
  `;

  const {
    error,
    loading,
    data: tdata1,
    refetch,
  } = useQuery(FetchAllRestaurantIssues);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState("");

  useEffect(() => {
    refetch();
    if (tdata1) {
      console.log("data", data);
      setData(tdata1?.FetchAllRestaurantIssues?.issues);
      setSummary(tdata1?.FetchAllRestaurantIssues?.summary);
      setTotalEntries(tdata1?.FetchAllRestaurantIssues?.total_entries);
      setTotalPage(tdata1?.FetchAllRestaurantIssues?.total_pages);
    }
  }, [tdata1]);

  const [createIssueopen, setCreateIssueOpen] = useState(false);

  const [rightDrawer, setRightDrawer] = useState({
    open: false,
    issueId: null,
    lead_id: null,
  });
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  console.log("Right Drawer", rightDrawer);
  return (
    <div>
      <main>
        <SupportHeader
          search={search}
          setSearch={(e) => setSearch(e)}
          searchBy={searchBy}
          setsearchBy={(e) => setsearchBy(e)}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
          status={status}
          setStatus={(e) => setStatus(e)}
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
          storeType={storeType}
          setStoreType={(e) => setStoreType(e)}
          createIssue={(e) => setCreateIssueOpen(true)}
          refetch={() => refetch()}
        />
        <CardContiner summary={summary} />
        {/* {startDate} {endDate} */}
        <SupportTable
          toatalPage={toatalPage}
          totalEntries={totalEntries}
          page={page}
          setPage={(e) => setPage(e)}
          setRightDrawer={(e) => setRightDrawer(e)}
          data={data}
        />
        <Drawer
          open={createIssueopen}
          onClose={() => setCreateIssueOpen(false)}
          anchor={"right"}
        >
          <CreateIssue
            setToast={(e) => setToast(e)}
            onClose={() => {
              setCreateIssueOpen(false);
              refetch();
            }}
          />
        </Drawer>
        <RightDrawer
          open={rightDrawer?.open}
          index={4}
          id={rightDrawer.lead_id}
          issueId={rightDrawer.issueId}
          refresh={(e) => refetch()}
          onClose={() => {
            setRightDrawer({
              open: false,
              issueId: null,
              lead_id: null,
            });
          }}
        />
        <Toast
          data={toast}
          handleClose={() => {
            setToast({
              open: false,
              message: "",
              isSuccess: false,
            });
          }}
          open={toast.open}
        />
      </main>
    </div>
  );
}
