import React from "react";
const PencilIcon = window.location.origin + "/assets/Theme/pencil.svg";

export default function ThemeCard({ data, onEdit }) {
  return (
    <div className="theme-card">
      <div className="card-img">
        <img src={data?.cover_image} alt="" />
      </div>

      <div className="content">
        <div className="div1">
          <p>{data?.name}</p>
          <p>{Number(data?.price / 100).toFixed(2)}</p>
        </div>
        <div className="div2">
          <p>Catogery : {data?.theme_category?.category_name}</p>
          <p>Layout :{data?.theme_layout_code}</p>
        </div>
        <div className="color-box">
          <div className="edit-icon">
            <img onClick={() => onEdit(data)} src={PencilIcon} alt="" />
          </div>

          <div className="color-group">
            <div
              className="color-circle"
              style={{ backgroundColor: data?.extra_data?.primary_color }}
            ></div>
            <div
              className="color-circle"
              style={{ backgroundColor: data?.extra_data?.secondary_color }}
            ></div>
            <div className="divider"></div>
            <div
              className="color-circle"
              style={{ backgroundColor: data?.extra_data?.cta_text_color }}
            ></div>
            <div
              className="color-circle"
              style={{ backgroundColor: data?.extra_data?.disable_cta_color }}
            ></div>
            <div
              className="color-circle"
              style={{
                backgroundColor: data?.extra_data?.disable_cta_text_color,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
