import React, { useState } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import Api from "../../Core/Api";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsUserLogin, setUserInfo } from "./../../store/UserAction";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  var qs = require("qs");
  const formSumit = (e) => {
    e.preventDefault();
    setDisable(true);
    var data = qs.stringify({
      email: email,
      password: password,
    });
    Api.login(data)
      .then(function (response) {
        console.log(response.data);
        localStorage.setItem("token", response.data.data.token);
        dispatch(setUserInfo(response.data.data));
        dispatch(setIsUserLogin(true));
        setTimeout(() => {
          history.push("/");
        }, 500);
      })
      .catch(function (error) {
        console.log(error.response);
        setError(true);
        setDisable(false);
      });

    e.preventDefault();
  };

  return (
    <>
      <Grid>
        <form onSubmit={(e) => formSumit(e)}>
          <Paper
            elevation={10}
            style={{
              padding: 20,
              height: "60vh",
              width: 300,
              margin: "40px auto",
            }}
          >
            {error && (
              <Grid align="center">
                <h3 style={{ color: "red" }}>Invalid User details</h3>
              </Grid>
            )}
            <Grid align="center">
              <h1>Let's Get Started</h1>
            </Grid>

            <TextField
              id="standard-basic"
              required
              label="Enter Email"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              id="standard-basic"
              label="Enter Password"
              type="password"
              fullWidth
              required
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              style={{ marginTop: 50 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={disable}
            >
              Login
            </Button>
          </Paper>
        </form>
      </Grid>
    </>
  );
};

export default LoginPage;
