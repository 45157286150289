import React from "react";
import moment from "moment";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import CircularProgress from "@material-ui/core/CircularProgress";

const BackGround = window.location.origin + "/assets/Chat/chat-background.jpg";

const getTick = (msg) => {
  if (msg?.read_at) {
    return <BiCheckDouble className="read-msg-tick" />;
  } else if (msg?.delivered_at) {
    return <BiCheckDouble className="delivered-msg-tick" />;
  } else return <BiCheck className="single-msg-tick" />;
};

const MsgBar = ({ msg }) => {
  return (
    <div className={msg?.from === "TEAM" ? "right-text" : "left-text"}>
      <p>{msg?.message}</p>
      <div className="time">
        <p>{moment.unix(msg?.created_at).format("do MMM, hh:mm a")}</p>
        {msg?.from === "TEAM" && getTick(msg)}
      </div>
    </div>
  );
};
const NewMsgBar = ({ msg }) => {
  return (
    <div className={"right-text new-msg-bar"}>
      <p>{msg?.message}</p>
      <div className="time">
        <CircularProgress size={20} style={{ color: "black" }} />
      </div>
    </div>
  );
};

export default function ChatContainer({ messages, msgbox }) {
  return (
    <div
      style={{ backgroundImage: `url(${BackGround})` }}
      className="chat-screen-panel-container"
    >
      {messages?.map((data, key) => (
        <React.Fragment key={key}>
          {data?.isNew ? <NewMsgBar msg={data} /> : <MsgBar msg={data} />}
        </React.Fragment>
      ))}
      <div ref={msgbox}></div>
    </div>
  );
}
