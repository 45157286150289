import { Button } from "@material-ui/core";
import React from "react";

export default function PiplineCTA({ ...rest }) {
  return (
    <div style={{ marginLeft: "auto" }}>
      <Button {...rest} variant="contained" color="primary" size="small">
        View in PipeLine
      </Button>
    </div>
  );
}
