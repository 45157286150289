import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Api from "../../Core/Api";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";

const FetchIssue = gql`
  query FetchRestaurantBasicUsingMobile($merchant_mobile: BigInt) {
    FetchRestaurantBasicUsingMobile(merchant_mobile: $merchant_mobile) {
      restaurant_id
      restaurant_name
      lead_id
      store_type
    }
  }
`;

export default function CreateIssue({ onClose, setToast, refetch }) {
  const [text, setText] = useState("");
  const [leadid, setLeadid] = useState("");
  const [mobile, setMobile] = useState();
  const [localDate, setLocalDate] = useState(null);
  const [date, setDate] = useState();

  const handleDateChange = (date) => {
    setDate(Date.parse(`${date} 23:59:00 `));
    setLocalDate(date);
  };

  // const { error, loading, data: tdata1, refetch } = useQuery(FetchIssue);
  const [getData, { loading, data }] = useLazyQuery(FetchIssue, {
    variables: {
      merchant_mobile: mobile,
    },
  });

  // const [data, setData] = useState([]);

  // console.log("Tdata", tdata1);
  // useEffect(() => {
  //   if (tdata1) {
  //     console.log(tdata1);
  //     setData(tdata1?.FetchRestaurantBasicUsingMobile);
  //   }
  // }, [tdata1]);

  const handleChangeRes = (event) => {
    setLeadid(event.target.value);
  };
  const handleSearchMobile = () => {
    getData();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = {
      text: text,
      lead_id: leadid,
      follow_up_at: date,
    };
    Api.addNewFollowup(temp)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          onClose();
          setToast({
            open: true,
            message: "New Followup added!",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="add-followup-drawer">
      <h1>Add Followup</h1>

      <form onSubmit={(e) => handleSubmit(e)}>
        <OutlinedInput
          placeholder="Enter Mobile Number"
          style={{ height: "40px", marginTop: "20px" }}
          type="number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          helperText="Some important text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => handleSearchMobile()} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <p style={{ fontSize: "12px", marginTop: "3px" }}>
          Enter {mobile?.length || 0} -10 digit of mobile
        </p>

        {data?.FetchRestaurantBasicUsingMobile?.length > 0 && (
          <FormControl size="small" required style={{ marginTop: "20px" }}>
            <InputLabel>Select Store</InputLabel>
            <Select value={leadid} onChange={handleChangeRes} label="">
              {data?.FetchRestaurantBasicUsingMobile?.map((data, index) => (
                <MenuItem
                  key={index}
                  value={data?.lead_id}
                >{`${data.restaurant_name} - ${data.store_type}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {leadid && (
          <>
            <TextField
              style={{ marginTop: "20px" }}
              id="date"
              label="Select Date"
              type="date"
              value={localDate}
              required
              // type="datetime-local"
              onChange={(e) => handleDateChange(e.target.value)}
              // defaultValue="2017-05-24"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              required
              onChange={(e) => setText(e.target.value)}
              id="standard-required"
              label="Enter Text"
              variant="outlined"
              multiline
              style={{ marginTop: "30px" }}
              rows={4}
              value={text}
            />

            <Button
              type="submit"
              style={{ marginTop: "30px" }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </>
        )}
      </form>
    </div>
  );
}
