import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";
import clsx from "clsx";
//
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function getSteps() {
  return [
    "Alloted",
    "Rider Arrived At Store",
    "Out for Delivery",
    "Rider Arrived At Doorstep",
    "Deliverd",
  ];
}

export default function DeliveryStapper({ data: temp }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const data = [
    temp?.allotted_at,
    temp?.arrived_at,
    temp?.dispatched_at,
    temp?.customer_doorstep_at,
    temp?.delivered_at,
  ];

  useEffect(() => {
    setTimeout(() => {
      setActiveStep(setActive());
    }, 10);
  }, [temp]);

  const setActive = () => {
    let temp = 0;
    data.map((data, index) => {
      if (data) {
        temp += 1;
      }
    });
    return temp;
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        // connector={<ColorlibConnector />}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep > 0 && (
        <p className="subheading">
          {steps[activeStep - 1]} At &nbsp;
          {moment(data[activeStep - 1]).format("MMMM Do YYYY h:m: a")}
        </p>
      )}
    </div>
  );
}
