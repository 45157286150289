import React from "react";
import { FiMoreVertical } from "react-icons/fi";

export default function Header({ selected }) {
  return (
    <div className="header">
      <div className="left">
        <img src={"https://picsum.photos/200"} alt="" />
        <h1>{selected?.fname} </h1>
      </div>

      <FiMoreVertical className="more" />
    </div>
  );
}
