import React from "react";
import moment from "moment";
import UserName from "../../component/UserName";
import { useSelector } from "react-redux";
const verified = window.location.origin + "/assets/Verification/check.svg";
const note = window.location.origin + "/assets/PipeLine/note.svg";
const cutlery = window.location.origin + "/assets/PipeLine/cutlery.svg";
const rocket = window.location.origin + "/assets/PipeLine/rocket.svg";
const cart = window.location.origin + "/assets/PipeLine/cart.svg";

export default function PipeLineCard({ item, setDrawer }) {
  const { userInfo } = useSelector((state) => state);
  return (
    <div
      className="pipeline-card"
      onClick={() => {
        setDrawer({
          lead_id: item.restaurant_lead?.id,
          open: true,
        });
      }}
    >
      <div className="name">
        {item?.restaurant_lead?.store_type === "RESTAURANT" ? (
          <img src={cutlery} alt="" />
        ) : (
          <img src={cart} alt="" />
        )}
        <h1>{item.restaurant_lead.restaurant_name}</h1>
        {item.restaurant_lead.document_status === "VERIFIED" && (
          <img src={verified} alt="" />
        )}
      </div>
      <div className="tags">
        {item.restaurant_lead.tags?.slice(0, 2).map((data, index) => (
          <p key={index} style={{ backgroundColor: data?.color }}>
            {data?.text}
          </p>
        ))}
      </div>

      <div className="date">
        <img src={rocket} alt="" />
        <p>
          {moment.unix(item?.restaurant_lead?.created_at).format("Do MMM YYYY")}
        </p>
      </div>
      <div className="date">
        <img src={note} alt="" />
        <p>
          {moment.unix(item?.restaurant_lead?.updated_at).format("Do MMM YYYY")}
        </p>
      </div>
      {item?.restaurant_lead?.assignee && userInfo?.is_admin && (
        <div className="assing_to">
          <h1>
            {item?.restaurant_lead?.assignee?.fname + " "}
            {item?.restaurant_lead?.assignee?.lname && (
              <span>{item?.restaurant_lead?.assignee?.lname} </span>
            )}
          </h1>
        </div>
      )}
    </div>
  );
}
