import React, { useEffect, useState } from "react";
import "./style.css";
import DeliveryStapper from "./DeliveryStapper";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";
import { Button } from "@material-ui/core";

export default function SideDrawer({
  ref_order_id,
  handleCancelOrderModal,
  setDeliveryProvider,
  handleChangeProviderModal,
  handleClose,
  reload,
}) {
  const FetchSingleDeliveryOrder = gql`
    query {
      FetchSingleDeliveryOrder(ref_order_id: ${ref_order_id}) {
        ref_order_id
        order_no
        distance
        status
        taxes
        txn_mode
        subtotal
        service_chrg
        dlv_chrg
        packing_chrg
        discount
        timestamp
        grand_total
        currency{
          currency_symbol 
        }
        f_order_items {
          item_price
          item_qty
          item_total
        }

        f_order_delivery {
          rider_id
          rider_name
          allotted_at
          arrived_at
          dispatched_at
          estimated_pickup_at
          customer_doorstep_at
          delivered_at
          service
        }
        restaurant_details {
          address
          name
          mobile
          store_type
        }
        client_details {
          name
          address
          mobile
        }
        rider_details {
          name
          mobile
        }
      }
    }
  `;

  const FetchDeliveryPartner = gql`query{
    FetchDeliveryPartnerListForSupport(ref_order_id:${ref_order_id}){
      image_url,
      provider_name
      is_serviceable,
      range
    }
  }`;
  const {
    loading,
    error,
    data: tdata1,
    refetch,
  } = useQuery(FetchSingleDeliveryOrder);
  const {
    loading: loading2,
    error: error2,
    data: tdata2,
    refetch: refetch2,
  } = useQuery(FetchDeliveryPartner);
  const [data, setData] = useState("");
  const [deliveryPartner, setDeliveryPartner] = useState([]);

  console.log("item", data);
  useEffect(() => {
    if (tdata1) {
      console.log(tdata1.FetchSingleDeliveryOrder);
      setData(tdata1.FetchSingleDeliveryOrder);
    }
  }, [tdata1]);

  useEffect(() => {
    if (tdata2) {
      console.log("tdata2", tdata2);
      setDeliveryPartner(tdata2.FetchDeliveryPartnerListForSupport);
    }
  }, [tdata2]);

  useEffect(() => {
    refetch2();
    refetch();
  }, [reload]);

  const handleProviderChange = (item) => {
    if (item.is_serviceable) {
      setDeliveryProvider(item.provider_name);
      handleChangeProviderModal(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "10px",
          marginTop: "5px",
        }}
      >
        <img
          style={{ cursor: "pointer", alignSelf: "flex-end" }}
          src={window.location.origin + "/assets/Crossx.svg"}
          alt="cross"
          onClick={() => handleClose()}
        />
      </div>
      <div className="drawer">
        <div className="s1">
          <div className="s1_header">
            <div>
              <h1 className="s1_title">{data?.restaurant_details?.name}</h1>
              <p style={{ marginTop: "8px" }} className="subheading">
                {data?.restaurant_details?.address}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="auto-asign">
                <img
                  src={window.location.origin + "/assets/Delivery.svg"}
                  alt=""
                  style={{ marginRight: "5px" }}
                />
                {data?.status}
              </div>
              <div style={{ display: "flex" }} className="auto-asign">
                Auto Assign
              </div>
            </div>
          </div>
          <hr className="hrline" />
          <DeliveryStapper data={data?.f_order_delivery} />
          <hr className="hrline" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="subheading">Business Type</p>
              <h2 className="subtitle">
                {data?.restaurant_details?.store_type}
              </h2>
            </div>
            <div>
              <p className="subheading">Delivery Distance</p>
              <h2 className="subtitle">{data?.distance}KM</h2>
            </div>
            <div>
              <p className="subheading">Estimated Pickup Time</p>
              <h2 className="subtitle">
                {moment(data?.f_order_delivery?.estimated_pickup_at).format(
                  "MMMM Do YYYY hh : mm a"
                )}{" "}
              </h2>
            </div>
          </div>
          {/* Second data */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "34px",
            }}
          >
            <div>
              <p className="subheading">PickupLocation</p>
              <h2 className="subtitle">{data?.restaurant_details?.address}</h2>
            </div>
            <div>
              <p className="subheading">DeliveryLocation</p>
              <h2 className="subtitle">{data?.client_details?.address}</h2>
            </div>
          </div>
          <hr className="hrline" />
          <div>
            <h2 className="subtitle">Assigned Service Provider</h2>
            <div className="delivery-partner">
              {deliveryPartner?.map((item, index) => (
                <div className="delivery-partner-img">
                  {data?.f_order_delivery.service === item?.provider_name && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                          marginBottom: "-20px",
                        }}
                        src={window.location.origin + "/assets/CheckTick.svg"}
                        alt="tick"
                      />
                    </div>
                  )}

                  <img
                    onClick={() => handleProviderChange(item)}
                    style={{
                      height: "64px",
                      width: "88px",
                      objectFit: "contain",
                      padding: "0px 10px",
                      filter: item.is_serviceable ? null : "grayscale(100%)",
                    }}
                    key={index}
                    src={item?.image_url}
                    alt="deliveryPartner"
                  />
                </div>
              ))}
            </div>
          </div>
          <hr className="hrline" />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a href={`tel: ${data?.restaurant_details?.mobile}`}>
                <img
                  src={window.location.origin + "/assets/Phone.svg"}
                  alt=""
                  style={{ margin: "0px 13px" }}
                />
              </a>
              <div>
                <p>
                  Call <br /> Restaurant
                </p>
              </div>
            </div>
            {/* //ddddd */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a href={`tel: ${data?.client_details?.mobile}`}>
                <img
                  src={window.location.origin + "/assets/Phone.svg"}
                  alt=""
                  style={{ margin: "0px 13px" }}
                />
              </a>
              <div>
                <p>
                  Call <br /> Customer
                </p>
              </div>
            </div>
            {/* //dddd */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a href={`tel: ${data?.rider_details?.mobile}`}>
                <img
                  src={window.location.origin + "/assets/Phone.svg"}
                  alt=""
                  style={{ margin: "0px 13px" }}
                />
              </a>
              <div>
                <p>
                  Call <br /> Rider {data?.rider_details?.name}
                </p>
              </div>
            </div>
          </div>
          <hr className="hrline" />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => handleCancelOrderModal(true)}
              style={{ color: " #DA5A5A", border: "1px solid  #DA5A5A" }}
              variant="outlined"
            >
              Cancel Order
            </Button>
          </div>
        </div>
        <div className="s2">
          <h1 className="s2_title">Order Details</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p className="subheading2">ORDER NUMBER</p>
              <p className="subtitle2">{data.order_no}</p>
            </div>
            <div>
              <p style={{ textAlign: "right" }} className="subheading2">
                REFERENCE NUMBER
              </p>
              <p style={{ textAlign: "right" }} className="subtitle2">
                {data?.ref_order_id}
              </p>
            </div>
          </div>
          {/* Date */}
          <div>
            <p className="subheading2">Date</p>
            <p className="subtitle2">
              {moment(data?.timestamp).format("MMMM Do YYYY hh:mm a")}{" "}
            </p>
          </div>
          <hr className="line-dotted" />
          <div>
            <p className="subheading2">Order Status</p>
            <p className="subtitle2">{data?.status}</p>
          </div>
          <hr className="line-dotted" />
          <div>
            {data.f_order_items?.map((temp, index) => (
              <React.Fragment key={index}>
                <p className="subtitle2">{temp.item_name}</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="subtitle2">
                    {data.currency?.currency_symbol}
                    {temp.item_price / 100} X {temp.item_qty}
                  </p>
                  <p className="subtitle2">
                    {" "}
                    {data.currency?.currency_symbol}
                    {temp?.item_total / 100}
                  </p>
                </div>
              </React.Fragment>
            ))}
          </div>
          <hr className="line-dotted" />
          <div>
            <p className="subheading2">Order Mode</p>
            <div className="item-div2">
              <p>Payment Mode</p>
              <span>{data?.txn_mode}</span>
            </div>
            <div className="item-div2">
              <p>Subtotal</p>
              <span>
                {" "}
                {data.currency?.currency_symbol}
                {data?.subtotal / 100}
              </span>
            </div>
            <div className="item-div2">
              <p>Taxes</p>
              <span>
                {data.currency?.currency_symbol}
                {data?.taxes / 100}
              </span>
            </div>
            <div className="item-div2">
              <p>Services</p>
              <span>
                {data.currency?.currency_symbol}
                {data?.service_chrg / 100}
              </span>
            </div>
            <div className="item-div2">
              <p>Delivery Charge</p>
              <span>
                {data.currency?.currency_symbol}
                {data?.dlv_chrg / 100}
              </span>
            </div>
            <div className="item-div2">
              <p>Packing Charge</p>
              <span>
                {data.currency?.currency_symbol}
                {data?.packing_chrg / 100}
              </span>
            </div>
            <div className="item-div2">
              <p style={{ color: "#8CC63F" }}>Discount</p>
              <span style={{ color: "#8CC63F" }}>
                {data.currency?.currency_symbol}
                {data?.discount / 100}
              </span>
            </div>
            <div className="item-div2">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Total
              </p>
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                {data.currency?.currency_symbol}
                {data?.grand_total / 100}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
