import React, { useEffect, useState } from "react";
import Api from "../../Core/Api";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

export default function DrawerFilter({
  catogery,
  setCatogery,
  layout,
  setLayout,
}) {
  const [filters, setFilters] = useState({
    categories: [],
    layout_codes: [],
  });

  useEffect(() => {
    Api.getThemeFilter()
      .then((res) => {
        console.log("Filters", res);
        if (res?.data?.status_code) setFilters(res?.data?.data);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  return (
    <div className="right-drawer-filter">
      <FormControl
        style={{
          minWidth: "120px",
        }}
        variant="standard"
      >
        <InputLabel id="catogery-label">{"Catogery"}</InputLabel>
        <Select
          labelId="catogery-label"
          value={catogery}
          required
          onChange={(e) => setCatogery(e.target.value)}
        >
          {filters?.categories?.map((e) => (
            <MenuItem key={e.id} value={e?.id}>
              {e?.category_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{
          minWidth: "120px",
        }}
        variant="standard"
      >
        <InputLabel id="layout-label">{"Layout"}</InputLabel>
        <Select
          labelId="layout-label"
          value={layout}
          required
          onChange={(e) => setLayout(e.target.value)}
        >
          {filters?.layout_codes?.map((e, index) => (
            <MenuItem key={index} value={e}>
              {e.replace(/_/g, " ")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
