import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchBox from "../../component/StyledComponent/SearchBox";
import SortBy from "../../component/StyledComponent/SortBy";

import {
  Grid,
  TextField,
  FormControl,
  Toolbar,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import CustomRadioGroup from "../../component/StyledComponent/RadioGroup";
import Title from "../../component/StyledComponent/Title";
import { IconButton } from "@material-ui/core";
const Res_active = window.location.origin + "/assets/Res_active.svg";
const Res_inactive = window.location.origin + "/assets/Res_inactive.svg";
const Retail_active = window.location.origin + "/assets/Retail_active.svg";
const Retail_inactive = window.location.origin + "/assets/Retail_inactive.svg";
const Add = window.location.origin + "/assets/Leads/AddBig.svg";
const RefreshBig = window.location.origin + "/assets/Leads/RefreshBig.svg";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {
    flexGrow: 2,
    padding: theme.spacing(3),
  },
}));

function ManageDeliveryTopBar({
  search,
  setSearch,
  searchBy,
  setsearchBy,
  sortBy,
  setSortBy,
  status,
  setStatus,
  startDate,
  setStatDate,
  endDate,
  setEndDate,
  storeType,
  setStoreType,
  add,
  refetch,
}) {
  const classes = useStyles();

  const [store, setStore] = useState({
    business: true,
    retail: true,
  });
  const handleStore = (e) => {
    console.log(e.target.name);
    const temp = { ...store, [e.target.name]: !store[e.target.name] };
    // console.log("after", temp);
    if (temp.business === false && temp.retail === true) {
      setStoreType("RETAIL");
    } else if (temp.business === true && temp.retail === false) {
      setStoreType("RESTAURANT");
    } else if (temp.business === true && temp.retail === true) {
      setStoreType("ALL");
    } else {
      setStoreType("");
    }
    setStore(temp);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <div className="manage-delivery-header">
      <div className="div1">
        <Title title={"Manage Delivery"} />
        <div className="div1-img">
          {/* <div style={{ marginRight: "80px" }}>
            <IconButton>
              <img onClick={() => add()} src={Add} alt="d" />
            </IconButton>
            <IconButton>
              <img onClick={() => refetch()} src={RefreshBig} alt="" />
            </IconButton>
          </div> */}
          <div>
            {/* <IconButton>
              <img
                name="retail"
                onClick={(e) => handleStore(e)}
                src={store.retail ? Retail_active : Retail_inactive}
                alt="retail"
              />
            </IconButton>
            <IconButton>
              <img
                name="business"
                onClick={(e) => handleStore(e)}
                src={store.business ? Res_active : Res_inactive}
                alt="business"
              />
            </IconButton> */}
          </div>
          <SortBy sortBy={sortBy} setSortBy={setSortBy} />
        </div>
      </div>

      <div className="div2">
        <div>
          <SearchBox search={search} setSearch={(e) => setSearch(e)} />

          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"Order Status"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"ALL"}>All</MenuItem>
              <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
              <MenuItem value={"PREPARED"}>Prepared</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
              <MenuItem value={"QUEUE"}>Queue</MenuItem>
              <MenuItem value={"DISPATCHED"}>Dispatched</MenuItem>
              <MenuItem value={"PROCESSING"}>Processing</MenuItem>
              <MenuItem value={"COMPLETED"}>Completed</MenuItem>
              <MenuItem value={"PREPARING"}>Preparing</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          {/* <TextField
            id="date"
            label="Start Date"
            type="date"
            value={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setStatDate(e.target.value);
            }}
          /> */}
          {/* <TextField
            id="date"
            style={{ marginLeft: "20px" }}
            label="End Date"
            type="date"
            value={endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEndDate(e.target.value)}
          /> */}
        </div>
      </div>

      <div className="div3">
        <CustomRadioGroup searchBy={searchBy} setsearchBy={setsearchBy} />
      </div>
    </div>
  );
}

export default ManageDeliveryTopBar;
