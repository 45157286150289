import React, { useEffect, useState } from "react";
import Issue from "../Issues/Issue";
import Messenger from "../Messenger/Messenger";

export default function IssueMiddlewere({ refresh, index, id, issueId }) {
  const [showIssue, setShowIssue] = useState(true);
  const [loaing, setLoaing] = useState(true);
  const [tempid, setTempid] = useState(null);

  useEffect(() => {
    setLoaing(true);
    setTempid(issueId);
    if (index === 4) {
      setShowIssue(false);
    } else setShowIssue(true);
    setLoaing(false);
  }, []);
  if (loaing) return <h1>Loading</h1>;

  return (
    <div>
      {showIssue ? (
        <Issue
          lead_id={id}
          setIssueId={(e) => {
            setTempid(e);
            setShowIssue(false);
          }}
        />
      ) : (
        <Messenger
          refresh={refresh}
          showIssue={() => {
            setTempid(null);
            setShowIssue(true);
          }}
          id={tempid}
        />
      )}
    </div>
  );
}
