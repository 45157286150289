import React from "react";
import moment from "moment";

export default function IssueCard({ data, setIssueId }) {
  const setcolor = (data) => {
    switch (data) {
      case "OPEN":
        return {
          backgroundColor: "#3AD6FF",
          color: "white",
        };
      case "CLOSE":
        return { backgroundColor: "#FE9B6E", color: "white" };
      default:
        return;
    }
  };

  const getLastMsg = (arr) => {
    if (arr) {
      let lastindex = arr[arr.length - 1];
      console.log("Last data", arr[lastindex].text);
    } else return "";
  };
  return (
    <div className="issue-card" onClick={() => setIssueId(data.id)}>
      <div className="div1">
        {/* <h1>{data.restaurant_lead?.restaurant_name}</h1> */}
        <h1>{moment(data?.created_at).format("MMMM Do YYYY")}</h1>
      </div>
      <h2>{data?.type}</h2>
      <div className="div2">
        <p style={setcolor(data?.status)}> {data.status}</p>
      </div>
    </div>
  );
}
