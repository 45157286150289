import { IconButton } from "@material-ui/core";
import React, { useState } from "react";
const RefreshBig = window.location.origin + "/assets/Leads/RefreshBig.svg";

export default function RefreshCTA({ refetch }) {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    refetch();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <>
      <IconButton className="refresh-cta">
        <img
          className={loading && "image"}
          onClick={() => handleClick()}
          src={RefreshBig}
          alt=""
        />
      </IconButton>
    </>
  );
}
