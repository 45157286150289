import React from "react";
import InfoCard from "../../component/InfoCard";
const underReview =
  window.location.origin + "/assets/Verification/underReview.svg";
const pending = window.location.origin + "/assets/Verification/pending.svg";
const verified = window.location.origin + "/assets/Verification/verified.svg";
const rejected = window.location.origin + "/assets/Verification/rejected.svg";

export default function CardContiner({ data }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <InfoCard count={data?.verified} title={"Verified"} img={verified} />
      <InfoCard count={data?.pending} title={"Pending"} img={pending} />
      <InfoCard
        count={data?.under_review}
        title={"Under review"}
        img={underReview}
      />
      <InfoCard count={data?.rejected} title={"Rejected"} img={rejected} />
    </div>
  );
}
