import React, { useState, useEffect } from "react";
import UploadMenuHeader from "./UploadMenuHeader";
import CardContiner from "./CardContiner";
import { gql, useQuery } from "@apollo/client";
import UploadMenuTable from "./UploadMenuTable";
import Toast from "../../component/Toast/Toast";

export default function UploadMenu() {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  const [page, setPage] = useState(1);
  const [toatalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");
  const [status, setStatus] = useState("ALL"); //
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL");
  const [documentStatus, setDocumnetStatus] = useState("ALL");
  const [refresh, setRefresh] = useState(false);

  const [restaurants, setRestaurants] = useState([]);
  const [summary, setSummary] = useState("");

  const FetchMenuData = gql`
    query {
      FetchMenuData(
        page_no: ${page}
        status: "${status}"
        search_query: "${search}"
        search_by: "${searchBy}"
        store_type: "${storeType}"
        document_status: "${documentStatus}"
        sort_by:"${sortBy}"
        start_date: ${startDate === "" ? 0 : Date.parse(startDate)}
        end_date: ${endDate === "" ? 0 : Date.parse(endDate)}
      ) {
        current_page
        total_pages
        total_entries
        summary {
            total
            menu_pending
            menu_uploaded
          }
        restaurants {
          lead_id
          restaurant_name
          store_type
          created_at
          status
          document_status
          is_menu_uploaded
        }
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(FetchMenuData);

  useEffect(() => {
    refetch();
    if (data) {
      console.log("Menu Upload Data", data);
      setRestaurants(data?.FetchMenuData?.restaurants);
      setSummary(data?.FetchMenuData?.summary);
      setTotalEntries(data?.FetchMenuData?.total_entries);
      setTotalPage(data?.FetchMenuData?.total_pages);
    }
  }, [data, refresh]);

  return (
    <div>
      <main>
        <UploadMenuHeader
          search={search}
          setSearch={(e) => setSearch(e)}
          searchBy={searchBy}
          setsearchBy={(e) => setsearchBy(e)}
          sortBy={sortBy}
          setSortBy={(e) => setSortBy(e)}
          status={status}
          setStatus={(e) => setStatus(e)}
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
          storeType={storeType}
          setStoreType={(e) => setStoreType(e)}
          add={(e) => alert("Add")}
          refetch={() => setRefresh(!refresh)}
          documentStatus={documentStatus}
          setDocumnetStatus={(e) => setDocumnetStatus(e)}
        />
        <CardContiner data={summary} />
        <UploadMenuTable
          toatalPage={toatalPage}
          totalEntries={totalEntries}
          page={page}
          refresh={() => setRefresh(!refresh)}
          setPage={(e) => setPage(e)}
          setToast={(e) => setToast(e)}
          data={restaurants}
        />
      </main>
      <Toast
        data={toast}
        handleClose={() => {
          setToast({
            open: false,
            message: "",
            isSuccess: false,
          });
        }}
        open={toast.open}
      />
    </div>
  );
}
