import React from "react";
import { TextField, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export default function StartandEndDate({
  startDate,
  setStatDate,
  endDate,
  setEndDate,
}) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        id="date"
        label="Start Date"
        type="date"
        value={startDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setStatDate(e.target.value);
        }}
      />
      <TextField
        id="date"
        style={{ marginLeft: "20px" }}
        label="End Date"
        type="date"
        value={endDate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => setEndDate(e.target.value)}
      />

      {/* <Button
        variant="contained"
        color="primary"
        style={{
          marginLeft: "20px",
          borderRadius: "50%",
          height: "40px",
          width: "40px",
        }}
        size="small"
      >
        <CloseIcon />
      </Button> */}
      {startDate || endDate ? (
        <IconButton
          style={{
            height: "25px",
            width: "25px",
            marginLeft: "10px",
            backgroundColor: "grey",
            // alignSelf: "flex-end",
          }}
          onClick={() => {
            setStatDate("");
            setEndDate("");
          }}
        >
          <CloseIcon style={{ color: "White", fontSize: "17px" }} />
        </IconButton>
      ) : null}
    </div>
  );
}
