import React from "react";
import UserName from "../../UserName";
import moment from "moment";

export default function Card({ data }) {
  return (
    <div className="rd-activity-card">
      <div className="div1">
        <UserName
          fname={data?.perform_by?.fname}
          lname={data?.perform_by?.lname}
        />
        <div className="name">
          <h1>
            {`${data?.perform_by?.fname}  ${
              data.perform_by.lname ? data.perform_by.lname : ""
            }`}
            <br />
            <span>{moment.unix(data?.created_at).format("DD MMM YYYY")}</span>
          </h1>
        </div>
      </div>
      <div className="div2">
        <h1>{data?.text} </h1>
      </div>
      <div className="div1">
        {data?.assigned_to && (
          <UserName
            fname={data?.assigned_to?.fname}
            lname={data?.assigned_to?.lname}
          />
        )}

        <div className="name">
          {data?.assigned_to && (
            <h1>{`${data?.assigned_to?.fname}  ${
              data.assigned_to.lname ? data.assigned_to.lname : ""
            }`}</h1>
          )}
        </div>
      </div>
    </div>
  );
}
