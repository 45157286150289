import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Dialog,
  makeStyles,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Api from "../../Core/Api";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";

const FetchIssue = gql`
  query FetchRestaurantBasicUsingMobile($merchant_mobile: BigInt) {
    FetchRestaurantBasicUsingMobile(merchant_mobile: $merchant_mobile) {
      restaurant_id
      restaurant_name
      lead_id
      store_type
    }
  }
`;

export default function CreateIssue({ onClose, setToast }) {
  const [text, setText] = useState("");
  const [selected, setSelected] = useState("");
  const [otherIssue, setOtherIssue] = useState("");
  const [leadid, setLeadid] = useState("");
  const [mobile, setMobile] = useState();

  // const { error, loading, data: tdata1, refetch } = useQuery(FetchIssue);
  const [getData, { loading, data }] = useLazyQuery(FetchIssue, {
    variables: {
      merchant_mobile: mobile,
    },
  });
  // const [data, setData] = useState([]);

  // console.log("Tdata", tdata1);
  // useEffect(() => {
  //   if (tdata1) {
  //     console.log(tdata1);
  //     setData(tdata1?.FetchRestaurantBasicUsingMobile);
  //   }
  // }, [tdata1]);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };
  const handleChangeRes = (event) => {
    setLeadid(event.target.value);
  };
  const handleSearchMobile = () => {
    console.log(mobile);
    getData();
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const temp = {
      text: text,
      lead_id: leadid,
      type: selected,
    };
    Api.createIssue(temp)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          // setOpen(true);
          onClose();
          setToast({
            open: true,
            message: "New Issue Created",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wrong",
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="create-issue-modal">
      <h1>Create Issue</h1>
      <form onSubmit={(e) => handleSubmit(e)}>
        <OutlinedInput
          placeholder="Enter Mobile Number"
          margin="normal"
          type="number"
          style={{ height: "40px", marginTop: "20px" }}
          onChange={(e) => setMobile(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleSearchMobile()}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <p style={{ fontSize: "12px", marginTop: "3px" }}>
          Enter {mobile?.length || 0} -10 digit of mobile
        </p>

        {data?.FetchRestaurantBasicUsingMobile?.length > 0 && (
          <FormControl
            variant="outlined"
            required
            style={{ marginTop: "20px" }}
          >
            <InputLabel>Select Restaurant</InputLabel>
            <Select value={leadid} onChange={handleChangeRes} label="Age">
              {data?.FetchRestaurantBasicUsingMobile?.map((data, index) => (
                <MenuItem
                  value={data?.lead_id}
                >{`${data.restaurant_name} - ${data.store_type}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {leadid && (
          <>
            <FormControl
              margin="normal"
              variant="outlined"
              required
              style={{ marginTop: "20px" }}
            >
              <InputLabel>Select Issue</InputLabel>
              <Select value={selected} onChange={handleChange} label="Age">
                <MenuItem value={"GENERAL"}>General</MenuItem>
                <MenuItem value={"BILLING"}>Billing</MenuItem>
                <MenuItem value={"MENU"}>Menu</MenuItem>
                <MenuItem value={"ACCOUNT"}>Account</MenuItem>
                <MenuItem value={"OTHER"}>Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              onChange={(e) => setText(e.target.value)}
              id="standard-required"
              label="Enter Message"
              variant="outlined"
              multiline
              margin="normal"
              rows={4}
              value={text}
            />

            <Button
              type="submit"
              style={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </>
        )}
      </form>
    </div>
  );
}
