import React from "react";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import UserName from "./../../component/UserName";
import CustomPagination from "../../component/CustomPagination";
const shoppingcart = window.location.origin + "/assets/shopping-cart.svg";
const cutlery = window.location.origin + "/assets/cutlery.svg";
const awating = window.location.origin + "/assets/Support/Awaiting.svg";
const replypending =
  window.location.origin + "/assets/Support/Reply Pending.svg";
const verified = window.location.origin + "/assets/Verification/check.svg";

const useStyles2 = makeStyles({
  table: {
    width: "100%",
    marginBottom: "20px",
  },
});

export default function SupportTable({
  data,
  setRightDrawer,
  toatalPage,
  page,
  setPage,
  totalEntries,
}) {
  const classes = useStyles2();
  const activationStatus = (data) => {
    switch (data) {
      case "RESOLVED":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginRight: "10px" }} src={verified} alt="icon" />{" "}
            <>Resolved</>
          </div>
        );
      case "AWAITING_RESPONSE":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginRight: "10px" }} src={awating} alt="icon" />{" "}
            <>Awating</>
          </div>
        );
      case "PENDING_REPLY":
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ marginRight: "10px" }}
              src={replypending}
              alt="icon"
            />{" "}
            <>Pending Replay</>
          </div>
        );

      default:
        return <>...</>;
    }
  };

  const setPriority = (data) => {
    switch (data) {
      case "HIGH":
        return {
          color: "Red",
        };
      case "MEDIUM ":
        return { color: "Yellow" };
      default:
        return null;
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow style={{ backgroundColor: "rgba(98, 0, 238, 0.12)" }}>
            <TableCell style={{ fontWeight: "bold" }}>{"Store Name"}</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Business"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Issue Date"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Activity Time"}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {"Ticket Status"}
            </TableCell>
            <TableCell align="right" style={{ fontWeight: "bold" }}>
              {"Resolved By"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowindex) => (
            <TableRow
              onClick={() =>
                setRightDrawer({
                  open: true,
                  issueId: row.id,
                  lead_id: row.restaurant_lead?.id,
                })
              }
              key={rowindex}
            >
              <TableCell component="th" scope="row">
                {row.restaurant_lead?.restaurant_name}
              </TableCell>
              <TableCell align="center">
                {row?.restaurant_lead?.store_type === "RESTAURANT" ? (
                  <img src={cutlery} alt="" />
                ) : (
                  <img src={shoppingcart} alt="" />
                )}
              </TableCell>
              <TableCell align="right">
                {/* {row.created_at} */}
                {moment(row?.created_at).format("Do MMM YYYY")}
              </TableCell>
              <TableCell align="center">
                {/* {row.id}--Lead{row.restaurant_lead?.id} */}
                {/* {row?.priority} */}
                <span style={setPriority(row?.priority)}>
                  {moment(row?.last_message_at).format(
                    "Do MMM YYYY hh:mm:SS a"
                  )}
                </span>
              </TableCell>

              <TableCell align="center">
                {/* {row?.full_status} */}
                {activationStatus(row?.full_status)}
              </TableCell>
              <TableCell align="right">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {row?.resolved_by?.fname ? (
                    <UserName
                      fname={row?.resolved_by?.fname}
                      lname={row?.resolved_by?.lname}
                    />
                  ) : (
                    "---"
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomPagination
        totalEntries={totalEntries}
        toatalPage={toatalPage}
        page={page}
        setPage={setPage}
      />
    </TableContainer>
  );
}
