import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchBox from "../../component/StyledComponent/SearchBox";

import {
  Grid,
  TextField,
  FormControl,
  Toolbar,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import CustomRadioGroup from "../../component/StyledComponent/RadioGroup";
import RefreshCTA from "../../component/StyledComponent/RefreshCTA";
import Title from "../../component/StyledComponent/Title";
import SortBy from "../../component/StyledComponent/SortBy";
import { IconButton } from "@material-ui/core";
import StartandEndDate from "../../component/StyledComponent/StartandEndDate";
const Res_active = window.location.origin + "/assets/Res_active.svg";
const Res_inactive = window.location.origin + "/assets/Res_inactive.svg";
const Retail_active = window.location.origin + "/assets/Retail_active.svg";
const Retail_inactive = window.location.origin + "/assets/Retail_inactive.svg";
const Add = window.location.origin + "/assets/Leads/AddBig.svg";
const RefreshBig = window.location.origin + "/assets/Leads/RefreshBig.svg";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  content: {
    flexGrow: 2,
    padding: theme.spacing(3),
  },
}));

function SupportHeader({
  search,
  setSearch,
  searchBy,
  setsearchBy,
  sortBy,
  setSortBy,
  status,
  setStatus,
  startDate,
  setStatDate,
  endDate,
  setEndDate,
  storeType,
  setStoreType,
  add,
  refetch,
  documentStatus,
  setDocumnetStatus,
}) {
  const classes = useStyles();

  const [store, setStore] = useState({
    business: true,
    retail: true,
  });
  const handleStore = (e) => {
    console.log(e.target.name);
    const temp = { ...store, [e.target.name]: !store[e.target.name] };
    // console.log("after", temp);
    if (temp.business === false && temp.retail === true) {
      setStoreType("RETAIL");
    } else if (temp.business === true && temp.retail === false) {
      setStoreType("RESTAURANT");
    } else if (temp.business === true && temp.retail === true) {
      setStoreType("ALL");
    } else {
      setStoreType("");
    }
    setStore(temp);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <div className="upload-menu-header">
      <div className="div1">
        <Title title={"Upload Menu"} />
        <div className="div1-img">
          <div style={{ marginRight: "80px" }}>
            <IconButton>
              <img onClick={() => add()} src={Add} alt="d" />
            </IconButton>
            <RefreshCTA refetch={() => refetch()} />
          </div>
          <div>
            <IconButton>
              <img
                name="retail"
                onClick={(e) => handleStore(e)}
                src={store.retail ? Retail_active : Retail_inactive}
                alt="retail"
              />
            </IconButton>
            <IconButton>
              <img
                name="business"
                onClick={(e) => handleStore(e)}
                src={store.business ? Res_active : Res_inactive}
                alt="business"
              />
            </IconButton>
          </div>
          <SortBy sortBy={sortBy} setSortBy={setSortBy} />
        </div>
      </div>

      <div className="div2">
        <div>
          <SearchBox search={search} setSearch={(e) => setSearch(e)} />

          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"Filter by activation"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"ACTIVATED"}>ACTIVATED</MenuItem>
              <MenuItem value={"ONBOARDING"}>ONBOARDING</MenuItem>
              <MenuItem value={"READY_TO_ACTIVE"}>READY TO ACTIVE</MenuItem>
              <MenuItem value={"ALL"}>ALL</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            style={{
              minWidth: "150px",
              backgroundColor: "white",
              marginLeft: "30px",
            }}
            variant="standard"
          >
            <InputLabel>{"Filter by document"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={documentStatus}
              onChange={(e) => {
                setDocumnetStatus(e.target.value);
              }}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
              <MenuItem value={"PENDING"}>PENDING</MenuItem>
              <MenuItem value={"UNDER_REVIEW"}>UNDER REVIEW</MenuItem>
              <MenuItem value={"VERIFIED"}>VERIFIED</MenuItem>
              <MenuItem value={"ALL"}>ALL</MenuItem>
            </Select>
          </FormControl>
        </div>
        <StartandEndDate
          startDate={startDate}
          setStatDate={(e) => setStatDate(e)}
          endDate={endDate}
          setEndDate={(e) => setEndDate(e)}
        />
      </div>

      <div className="div3">
        <CustomRadioGroup searchBy={searchBy} setsearchBy={setsearchBy} />
      </div>
    </div>
  );
}

export default SupportHeader;
