import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import DocList from "./DocList";
import { gql, useQuery } from "@apollo/client";
import PaymentMehod from "./PaymentMehod";
import BtnBar from "./BtnBar";
import PdfViewer from "../../PdfViewer/PdfViewer";
import ImageDialog from "../../ImageDialog/ImageDialog";
import Api from "./../../../Core/Api";
import Toast from "../../Toast/Toast";
import RejectDialog from "./RejectDialog";
import PiplineCTA from "../../StyledComponent/PiplineCTA";
import { Link } from "react-router-dom";

export default function Documents({ id, open, onClose }) {
  const fetchDoc = gql`
    query {
      FetchRestaurantDocument(lead_id: ${id}) {
        pan_card_link
        pan_card_number
        food_license_link
        food_license_number
        id_card_link
        id_card_number
        gst_number
        status
        document_status
        cancel_cheque_link
        payment_method
        is_document_verified
        account_number
        account_holder_name
        ifsc_code
        upi_id
      }
    }
  `;

  const { data: docs, loading, error, refetch } = useQuery(fetchDoc);

  const [process, setProcess] = useState(false);
  const [process2, setProcess2] = useState(true);
  const [data, setData] = useState("");
  const [rejectDialog, setRejectDialog] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });
  const [imageModal, setImageModal] = useState({
    open: false,
    type: "",
    text: "",
    img: null,
  });
  const [pdfModal, setPdfModal] = useState({
    open: false,
    type: "",
    text: "",
    pdf: null,
  });

  useEffect(() => {
    refetch();
  }, [open]);

  useEffect(() => {
    if (docs) {
      console.log("Documets", docs);
      setData(docs?.FetchRestaurantDocument);
    }
  }, [docs]);

  const downloadFile = (url) => {
    console.log(url);
    window.open(url, "_blank");
  };

  const uploadExcelMenu = (e) => {
    setProcess(true);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log(e.target.files[0]);
      var data = new FormData();
      data.append("excel_menu", file);
      data.append("lead_id", id);
      Api.excelMenuUpload(data)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.data.is_menu_valid === false) {
            downloadFile(response.data.data.excel_url);
          } else if (response.data.data.is_menu_valid === true) {
            setProcess(false);
            setShowSave(true);
          } else {
            setProcess(false);
            setShowSave(false);
            setToast({
              open: true,
              message: "Something went Wrong",
              isSuccess: false,
            });
          }
        })
        .catch(function (error) {
          console.log("Error while uploading", error);
          setProcess(false);
          setShowSave(false);
          setToast({
            open: true,
            message: "Something went Wrong",
            isSuccess: false,
          });
        });
    } else {
      console.log("No file choosed");
    }
  };

  const save = () => {
    var qs = require("qs");

    setProcess(true);

    var data = qs.stringify({
      lead_id: id,
    });
    Api.save(data)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        if (response.data.is_menu_valid || response.data.status_code === 200) {
          setShowSave(false);
          setProcess(false);
          setToast({
            open: true,
            message: "Uploaded!",
            isSuccess: true,
          });
        } else if (response.data.status === "Failed") {
          setToast({
            open: true,
            message: response?.data?.error_message,
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const verify = () => {
    var data = {
      lead_id: id,
    };
    Api.verify(data)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          setToast({
            open: true,
            message: "Verifed!",
            isSuccess: true,
          });
        } else if (response.data.status_code === 400) {
          setToast({
            open: true,
            message: response?.data?.error_message,
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const reject = (text) => {
    setRejectDialog(false);

    var data = {
      lead_id: id,
      reason: text,
    };
    Api.reject(data)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 200) {
          refetch();
          setToast({
            open: true,
            message: "Rejected!",
            isSuccess: true,
          });
        } else if (response.data.status_code === 400) {
          setToast({
            open: true,
            message: response?.data?.error_message,
            isSuccess: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="rd-documents">
      <div className="topbar">
        <h1>Document</h1>
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => uploadExcelMenu(e)}
        />
        <label htmlFor="contained-button-file">
          {showSave === true ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => save()}
            >
              {process ? (
                <CircularProgress color="inherit" size={20} thickness="5" />
              ) : (
                "Save"
              )}
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              component="span"
            >
              {process ? (
                <CircularProgress color="inherit" size={20} thickness="5" />
              ) : (
                "Upload Menu"
              )}
            </Button>
          )}
        </label>

        {/* <PiplineCTA
          component={Link}
          to={`/pipeline?id=${2}`}
          onClick={() => onClose()}
        /> */}
      </div>
      <PaymentMehod data={data} />
      <DocList
        data={data}
        setImageModal={(e) => setImageModal(e)}
        setPdfModal={(e) => setPdfModal(e)}
      />

      {data?.document_status === "UNDER_REVIEW" && (
        <BtnBar
          process2={process2}
          verify={() => verify()}
          reject={() => setRejectDialog(true)}
        />
      )}
      <RejectDialog
        open={rejectDialog}
        onClose={() => setRejectDialog(false)}
        reject={(e) => reject(e)}
      />

      <PdfViewer data={pdfModal} setPdfModal={(e) => setPdfModal(e)} />
      <ImageDialog
        imageModal={imageModal}
        setImageModal={(e) => setImageModal(e)}
      />

      <Toast
        data={toast}
        handleClose={() => {
          setToast({
            open: false,
            message: "",
            isSuccess: false,
          });
        }}
        open={toast.open}
      />
    </div>
  );
}
