import React from "react";
import moment from "moment";

export default function UserCard({ data, selected, setSelected, index }) {
  const getFirstName = (text) => {
    if (text?.length > 15) {
      return text.substring(0, 12) + "...";
    } else return text;
  };
  return (
    <div
      onClick={() => setSelected(data)}
      className="chat-screen-user-list-card"
      id={data?.r_p_id === selected?.r_p_id ? "selected-user-card" : null}
    >
      <div className="user-logo">
        <img src={"https://picsum.photos/200"} alt="" />
      </div>

      <div className="right">
        <div className="top">
          <h1>
            {data?.fname === "Guest" ? data?.mobile : getFirstName(data?.fname)}
          </h1>
          {data?.latest_message?.created_at && (
            <p>
              {moment.unix(data?.latest_message?.created_at).format("hh:mm a")}
            </p>
          )}
        </div>
        <div className="buttom">
          <p>{data?.latest_message?.message}</p>

          {data?.unread_count > 0 && (
            <div className="bedge">
              <p>{data?.unread_count}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
