import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import TopAppBar from "../../component/AuthCompo/Header";
import SideBar from "../../component/AuthCompo/SideNav";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  continerOpen: {
    marginLeft: "0px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: `${240}px`,
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },

  continerClose: {
    marginLeft: "0px",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

export default function Auth({ route }) {
  // const [open, setOpen] = useState(true);
  const { sidebarOpen } = useSelector((state) => state);
  const classes = useStyles();
  return (
    <div className="auth-screen">
      <TopAppBar />
      <SideBar open={sidebarOpen} />
      <div
        className={sidebarOpen ? classes.continerOpen : classes.continerClose}
      >
        {route}
      </div>
    </div>
  );
}
