import React, { useEffect, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import VerificationHeader from "./VerficationHeader";
import CardContiner from "../Verfication/CardContiner";
import VerificationTable from "./VerificationTable";
import RightDrawer from "../../component/RightDrawer/RightDrawer";

export default function AdminPanel() {
  const [open, setOpen] = React.useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [summary, setSummary] = useState("");

  const [page, setPage] = useState(1);
  const [toatalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CREATION_EARLIEST");
  const [status, setStatus] = useState("ALL"); //
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL"); //RESTAURANT RETAIL ALL
  const [documentStatus, setDocumnetStatus] = useState("ALL");

  const [selectedLeadId, setSelectedLeadId] = useState(null);

  const [refresh, setRefresh] = useState(false);
  const GET_RESTAURENT = gql`
    query {
      FetchVerificationStoresData(
        page_no: ${page}
        status: "${status}"
        search_query: "${search}"
        search_by: "${searchBy}"
        store_type: "${storeType}"
        document_status: "${documentStatus}"
        sort_by:"${sortBy}"
        start_date: ${startDate === "" ? 0 : Date.parse(startDate)}
        end_date: ${endDate === "" ? 0 : Date.parse(endDate)}
      ) {
        current_page
        total_pages
        total_entries
        summary {
          verified
          pending
          under_review
          rejected
        }
        restaurants {
          restaurant_id
          lead_id
          restaurant_name
          store_type
          status
          document_status
          verification_due_at
          created_at
          assigned_to
          assignee{
            fname
            lname
          }
          
        }
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(GET_RESTAURENT);

  useEffect(() => {
    refetch();
    if (data) {
      setRestaurants(data?.FetchVerificationStoresData?.restaurants);
      setSummary(data?.FetchVerificationStoresData?.summary);
      setTotalEntries(data?.FetchVerificationStoresData?.total_entries);
      setTotalPage(data?.FetchVerificationStoresData?.total_pages);
    }
  }, [data, refresh]);
  return (
    <>
      <div>
        {true ? (
          <main>
            <VerificationHeader
              search={search}
              setSearch={(e) => setSearch(e)}
              searchBy={searchBy}
              setsearchBy={(e) => setsearchBy(e)}
              sortBy={sortBy}
              setSortBy={(e) => setSortBy(e)}
              status={status}
              setStatus={(e) => setStatus(e)}
              startDate={startDate}
              setStatDate={(e) => setStatDate(e)}
              endDate={endDate}
              setEndDate={(e) => setEndDate(e)}
              storeType={storeType}
              setStoreType={(e) => setStoreType(e)}
              documentStatus={documentStatus}
              setDocumnetStatus={(e) => setDocumnetStatus(e)}
            />

            <CardContiner data={summary} />
            <VerificationTable
              toatalPage={toatalPage}
              totalEntries={totalEntries}
              page={page}
              setPage={(e) => setPage(e)}
              data={restaurants}
              setLeadId={(e) => {
                setSelectedLeadId(e);
                setOpen(true);
              }}
            />

            <RightDrawer
              open={open}
              index={2}
              id={selectedLeadId}
              onClose={() => {
                setSelectedLeadId(null);
                setOpen(false);
                refetch();
              }}
            />
          </main>
        ) : (
          <h1 style={{ textAlign: "center" }}>Loading...</h1>
        )}
      </div>
    </>
  );
}
