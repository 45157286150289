import { Paper, Box, Toolbar } from "@material-ui/core";
import React from "react";
import style from "./card.module.css";
import moment from "moment";
import "./style.css";

export default function Card({ data, handleOpen }) {
  return (
    <Paper elevation={0} style={{ margin: "10px" }}>
      <Box p={0}>
        <div className={style.card}>
          <div
            style={{ width: "40%" }}
            onClick={() => {
              handleOpen({
                open: true,
                ref_order_id: data.ref_order_id,
              });
            }}
          >
            <h1 className={style.name}>{data?.restaurant_details?.name}</h1>
            <p className={style.subtitle}>
              {" "}
              {data?.restaurant_details?.address}{" "}
            </p>
            <p className={style.subtitle}>
              Order Number <span> {data?.order_no} </span>
            </p>
            <p className={style.subtitle}>
              Estimated Pickup Time{" "}
              <span>
                {moment(data?.f_order_delivery?.estimated_pickup_at).format(
                  "MMMM Do YYYY hh:mm a"
                )}
              </span>
              {/* {data?.f_order_delivery?.estimated_pickup_at} */}
            </p>
            <p className={style.subtitle}>
              Order Date{" "}
              <span>
                {moment(data?.timestamp).format("MMMM Do YYYY hh:mm a")}
              </span>
              {/* {data?.timestamp} */}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "60%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="auto-asign">Auto Align</div>
              <div className="auto-asign">
                <img
                  src={window.location.origin + "/assets/Delivery.svg"}
                  alt=""
                  style={{ marginRight: "5px" }}
                />
                {data.f_order_delivery?.status}
              </div>
              <div className="auto-asign">
                <img
                  src={window.location.origin + "/assets/Paper.svg"}
                  alt=""
                  style={{ marginRight: "5px" }}
                />
                {data?.status}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href={`tel: ${data?.restaurant_details?.mobile}`}>
                  <img
                    src={window.location.origin + "/assets/Phone.svg"}
                    alt=""
                    style={{ margin: "0px 10px" }}
                  />
                </a>
                <div>
                  <p>Call Restaurant</p>
                </div>
              </div>
              {/* //ddddddd */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href={`tel: ${data?.client_details?.mobile}`}>
                  <img
                    src={window.location.origin + "/assets/Phone.svg"}
                    alt=""
                    style={{ margin: "0px 13px" }}
                  />
                </a>
                <div>
                  <p>Call Customer</p>
                </div>
              </div>
              {/* ///Ddddd */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href={`tel: ${data?.rider_details?.mobile}`}>
                  <img
                    src={window.location.origin + "/assets/Phone.svg"}
                    alt=""
                    style={{ margin: "0px 13px" }}
                  />
                </a>
                <div>
                  <p>Call Rider {data?.rider_details?.name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Paper>
  );
}
