import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import "./style.css";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Api from "../../Core/Api";

export default function ChangeProviderModal({
  ref_order_id,
  open,
  setToast,
  deliveryProvider,
  handleChangeProviderModal,
}) {
  const [selected, setSelected] = useState("Reason1");
  const [otherReason, setOtherReason] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  const handleRadioChange = (e, d) => {
    setShow(false);
    setSelected(d);
    if (d === "Other") {
      setShow(true);
    }
  };
  const handleSubmit = () => {
    let data;
    if (selected === "Other" && otherReason.length > 1) {
      data = {
        ref_order_id: ref_order_id,
        delivery_provider: deliveryProvider,
      };
    } else if (selected === "Other" && otherReason.length === 0) {
      setToast({
        open: true,
        message: "Enter Reason",
        isSuccess: false,
      });
      return;
    } else {
      data = {
        ref_order_id: ref_order_id,
        delivery_provider: deliveryProvider,
      };
    }
    console.log("Data", data);
    Api.ChangeServiceProvider(data)
      .then((response) => {
        console.log("Change Provider Response", response);
        if (response.data.status_code === 200) {
          handleChangeProviderModal(false);
          setToast({
            open: true,
            message: "Service Provider Changed",
            isSuccess: true,
          });
        } else {
          setToast({
            open: true,
            message: "Something Went Wront",
            isSuccess: false,
          });
        }
      })
      .catch((error) => console.log("Error", error));
  };
  const body = (
    <div className={"paper"}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <img
          style={{ cursor: "pointer" }}
          src={window.location.origin + "/assets/Crossx.svg"}
          alt="cross"
          onClick={() => handleChangeProviderModal(false)}
        />
      </div>

      <h1>Are you looking to switch service provider?</h1>
      <br />
      <FormControl error={error}>
        <FormLabel> Select the reason </FormLabel>
        <RadioGroup value={selected} onChange={handleRadioChange}>
          <FormControlLabel
            label="Reason1"
            control={<Radio value="Reason1" />}
          />
          <FormControlLabel
            label="Reason2"
            control={<Radio value="Reason2" />}
          />
          <FormControlLabel
            label="Reason3"
            control={<Radio value="Reason3" />}
          />
          <FormControlLabel label="Other" control={<Radio value="Other" />} />
        </RadioGroup>
      </FormControl>
      {show && (
        <TextField
          style={{ margin: "0px 10%", marginTop: "20px" }}
          size="small"
          onChange={(e) => setOtherReason(e.target.value)}
          variant="outlined"
          placeholder="Enter Reason"
        />
      )}

      <Button variant="contained" onClick={() => handleSubmit()}>
        {" "}
        Submit{" "}
      </Button>
    </div>
  );

  return (
    <Modal
      className="modal"
      open={open}
      onClose={() => handleChangeProviderModal(false)}
    >
      {body}
    </Modal>
  );
}
