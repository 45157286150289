import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Drawer } from "@material-ui/core";
import FollowupHeader from "./FollowUpHeader";
import FollowUpTable from "./FollowUpTable";
import AddFollowup from "./AddFollowup";
import CardContiner from "./CardContiner";
import RightDrawer from "../../component/RightDrawer/RightDrawer";
import Toast from "../../component/Toast/Toast";
import { useLocation } from "react-router-dom";

export default function Followup() {
  const location = useLocation();
  const [isNext, setIsNext] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  // window.onscroll = function (ev) {
  //   if (
  //     window.innerHeight + window.scrollY + 50 >=
  //     document.body.offsetHeight
  //   ) {
  //     if (isNext && !ongoing) {
  //       setPage(page + 1);
  //       setOngoing(true);
  //     }
  //   }
  // };

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(""); //
  const [searchBy, setsearchBy] = useState("RESTAURANT_NAME"); //
  const [sortBy, setSortBy] = useState("CALENDAR");
  const [status, setStatus] = useState("ALL"); //
  const [startDate, setStatDate] = useState(""); //
  const [endDate, setEndDate] = useState(""); //
  const [storeType, setStoreType] = useState("ALL"); //RESTAURANT RETAIL ALL
  const [refetched, setRefetched] = useState(false);
  const [lead_id, setLeadId] = useState("");

  const FetchFollowup = gql`
    query {
      FetchAllFollowupWithSummary(
        page_no:${page}
        status:${status}
        from: ${startDate === "" ? 0 : Date.parse(startDate)}
        to: ${endDate === "" ? 0 : Date.parse(endDate)}
        search_query: "${search}"
        search_by: "${searchBy}"
        store_type: "${storeType}"
        sort_by:"${sortBy}"
      ) {
        is_next_page  
        summary {
          unattended
          upcomming
          missed
          action_required
        }
        followups {
          lead_id
          follow_up_at
          text
          status
          done_at
          restaurant_lead {
            restaurant_name
            store_type
          }
        }
      }
    }
  `;

  const { error, loading, data, refetch } = useQuery(FetchFollowup);

  const [followups, setFollowups] = useState([]);
  const [summary, setSummary] = useState("");
  const [loadingStatus, setShowLoadingStatus] = useState(true);

  useEffect(() => {
    refetch();
    if (data) {
      console.log(data);
      // if (data?.FetchAllFollowupWithSummary.is_next_page === true) {
      //   setShowLoadingStatus(true);
      // } else if (data?.FetchAllFollowupWithSummary.is_next_page === false) {
      //   setShowLoadingStatus(false);
      // }
      setIsNext(data?.FetchAllFollowupWithSummary.is_next_page);
      // setOngoing(false);
      setFollowups([
        ...followups,
        ...data?.FetchAllFollowupWithSummary?.followups,
      ]);
      setSummary(data?.FetchAllFollowupWithSummary?.summary);
    }
  }, [data]);

  const [open, setOpen] = useState(false);
  const [openAddfollowup, setOpenAddfollowup] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    isSuccess: false,
  });

  return (
    <div>
      <main>
        <FollowupHeader
          search={search}
          setSearch={(e) => {
            setPage(1);
            setFollowups([]);
            setSearch(e);
          }}
          searchBy={searchBy}
          setsearchBy={(e) => {
            setPage(1);
            setFollowups([]);
            setsearchBy(e);
          }}
          sortBy={sortBy}
          setSortBy={(e) => {
            setPage(1);
            setFollowups([]);
            setSortBy(e);
          }}
          status={status}
          setStatus={(e) => {
            setPage(1);
            setFollowups([]);
            setStatus(e);
          }}
          startDate={startDate}
          setStatDate={(e) => {
            setPage(1);
            setFollowups([]);
            setStatDate(e);
          }}
          endDate={endDate}
          setEndDate={(e) => {
            setPage(1);
            setFollowups([]);
            setEndDate(e);
          }}
          storeType={storeType}
          setStoreType={(e) => {
            setPage(1);
            setFollowups([]);
            setStoreType(e);
          }}
          addfollowup={(e) => setOpenAddfollowup(true)}
          refetch={(e) => {
            refetch();
          }}
        />
        <CardContiner summary={summary} />

        <FollowUpTable
          isNext={isNext}
          setPage={(e) => setPage(e)}
          page={page}
          setLeadId={(e) => {
            setLeadId(e);
            setOpen(true);
          }}
          data={followups}
        />
        <RightDrawer
          open={open}
          index={1}
          id={lead_id}
          onClose={() => {
            setOpen(false);
          }}
        />
        <Drawer
          open={openAddfollowup}
          onClose={() => setOpenAddfollowup(false)}
          anchor={"right"}
        >
          <AddFollowup
            setToast={(e) => setToast(e)}
            refetch={() => refetch()}
            onClose={() => {
              setOpenAddfollowup(false);
            }}
          />
        </Drawer>
        <Toast
          data={toast}
          handleClose={() => {
            setToast({
              open: false,
              message: "",
              isSuccess: false,
            });
          }}
          open={toast.open}
        />
      </main>
    </div>
  );
}
