import React, { useState, useEffect } from "react";
import UserList from "./ListPanel/UserList";
import ChatPanel from "./ChatPanel/ChatPanel";
import EmptyChatPanel from "./EmptyChatPanel";
import Api from "./../../Core/Api";
import socketIOClient from "socket.io-client";
import { baseURL } from "./../../Core/Api";

const ENDPOINT = baseURL;

export default function Chat() {
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [unReadFilter, setUnreadFilter] = useState("ALL");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [handleLoading, setHandleLoading] = useState(true);

  useEffect(() => {
    getUserList();
  }, [refresh]);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.emit("whatsapp-message-listner", `${1}`); // user id

    socket.on("MESSAGE_READ", (data) => {
      console.log("Message Read", data);
    });
    socket.on("NEW_MESSAGE", (data) => {
      console.log("New Message", data);
      // setHandleLoading(true); // hide loader
      // setUserList([]); // empty list
      setPage(1);
      // setRefresh(!refresh);
      // handleNewMessage(data);

      Api.getContacts(search, 1, unReadFilter)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            setUserList(response?.data?.data?.contacts);
            setIsNext(response?.data?.data?.hasNext);
          }
        })
        .catch((e) => console.log("Error", e));
    });
  }, []);

  const handleNewMessage = (data) => {
    const { message, r_p_id } = data;

    console.log("NM", data);
    var templist = [];
    templist = [...userList];
    let tempIndex = userList.findIndex((e) => e.r_p_id === r_p_id);
    console.log("Temp index", tempIndex);
    console.log("Temp List", templist);
    if (tempIndex !== -1) {
      let tmsg = templist[tempIndex];
      tmsg.latest_message.message = message;
      tmsg.unread_count += 1;
      templist[tempIndex] = tmsg;
      setUserList(templist);
      templist = [];
      tempIndex = -1;
      console.log("Listdd", templist[tempIndex]);
    }
  };

  const getUserList = () => {
    handleLoading && setLoading(true);
    Api.getContacts(search, page, unReadFilter)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          setUserList([...userList, ...response?.data?.data?.contacts]);
          setIsNext(response?.data?.data?.hasNext);
          setLoading(false);
        }
      })
      .catch((e) => console.log("Error", e));
  };

  return (
    <div className="chat-screen">
      <UserList
        loading={loading}
        search={search}
        setSearch={(e) => {
          setHandleLoading(true);
          setUserList([]);
          setSearch(e);
          setPage(1);
          setRefresh(!refresh);
        }}
        unReadFilter={unReadFilter}
        setUnreadFilter={(e) => {
          setHandleLoading(true);
          setUserList([]);
          setUnreadFilter(e);
          setPage(1);
          setRefresh(!refresh);
        }}
        isNext={isNext}
        list={userList}
        selected={selected}
        setSelected={(e) => setSelected(e)}
        handleScrollDown={() => {
          setHandleLoading(false);
          setPage(page + 1);
          setRefresh(!refresh);
        }}
      />
      {selected ? <ChatPanel selected={selected} /> : <EmptyChatPanel />}
    </div>
  );
}
