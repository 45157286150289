import React, { useState } from "react";
// import Alert from "@material-ui/lab/Alert";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
const copy = window.location.origin + "/assets/copy.svg";
const send = window.location.origin + "/assets/send.svg";

export default function LiveMenuBar({
  link,
  pipelineid,
  changePipeline,
  pipeLineList,
  index,
}) {
  const [text, setText] = useState("Copy Link");
  return (
    <div>
      <div className="live-menu">
        {index === 0 && pipeLineList && (
          <div>
            <FormControl>
              <InputLabel htmlFor="filled-age-native-simple1">
                {"Pipeline"}
              </InputLabel>
              <Select
                value={pipelineid}
                onChange={(e) => changePipeline(e.target.value)}
                inputProps={{
                  name: "age1",
                  id: "filled-age-native-simple1",
                }}
              >
                {pipeLineList.map((user, index) => (
                  <MenuItem key={index} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div style={{ display: link ? "block" : "none" }}>
          <h3>Live Menu</h3>
          <Button
            startIcon={<img src={copy} alt="copy" />}
            onClick={() => {
              navigator.clipboard.writeText(`${link}`);
              setText("Copied !");
              setTimeout(() => {
                setText("Copy Link");
              }, [3000]);
            }}
            variant="contained"
            size="small"
            color="primary"
          >
            {text}
          </Button>
          <a
            style={{ textDecoration: "none" }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              startIcon={<img src={send} alt="copy" />}
              style={{ marginLeft: "5px" }}
              variant="contained"
              size="small"
              color="primary"
            >
              Go to Link
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
