import { USER_INFO, SET_IS_USER_LOGIN, SET_SIDEBAROPEN } from "./UserAction";

const initialState = {
  islogin: false,
  sidebarOpen: true,
  userInfo: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_USER_LOGIN:
      return {
        ...state,
        islogin: action.payload,
      };
    case SET_SIDEBAROPEN:
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

export { userReducer };
